@import "../../../scss/_common/common";

.wrap {
  width: 640px;
  position: relative;

  @media screen and (max-width: 1020px) {
    width: auto;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  position: relative;
  max-width: max-content;
  margin-bottom: 24px;
}

.btn_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  & > button {
    width: 116px;

    @media screen and (max-width: 1020px) {
      width: auto;
    }
  }
}

.hidden_block {
  overflow: hidden;
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.text_confirm {
  color: #000;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}