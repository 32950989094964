@import "../../scss/_common/common";

.img_block {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.media {
  height: 100%;
  * {
    margin-bottom: 0 !important;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & > div > div {
    height: 60px !important;
  }
}
