@import "src/scss/_common/common";

.checkbox {
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  cursor: pointer;
  right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #bfc0c4;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
  }

  &:after {
    width: 20px;
    background: #b9c4d3;
    box-shadow: 2px 0 3px rgba(201, 219, 233, 0.3), inset -1px -1px 1px #95a4b8, inset 1px 1px 1px #e4eaf2;
  }

  &:before {
    width: 40px;
    background: #f6f8fa;
    box-shadow: inset 2px 2px 5px #d2dceb;
  }

  input {
    opacity: 0;
    overflow: hidden;
    width: 40px;
    height: 20px;
    position: relative;
    cursor: pointer;
    z-index: 1;
  }

  &.active {
    color: #000;

    &:after {
      left: 20px;
      background: #58bf8e;
      box-shadow: -2px 0px 3px rgba(201, 219, 233, 0.6), inset -1px -1px 1px #3da674, inset 1px 1px 1px #95dfbc;
    }
  }

  &_reverse {
    right: auto;
    left: 0;
  }
}

.switch {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 20px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin-right: 70px;
    cursor: pointer;

    &_reverse {
      margin-right: 0;
      margin-left: 52px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}