@import "src/scss/_common/common";

.wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.circle {
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 6px;
}

.green {
  background-color: #34a853;
}

.red {
  background-color: #da3b2f;
}

.yellow {
  background-color: #e2cd0b;
}

.grey {
  background-color: #828282;
}

.blue {
  background-color: #22a4cd;
}

