@import "../../scss/_common/common";

.inner {
  display: flex;
  justify-content: space-between;
  form {
    width: 100%;
  }

  label {
    max-width: 100%;
  }
}

form.wrapper {
  display: flex;
  padding: 0;
  gap: 32px;

  & > div[class*='TreeCredit_block'] {
    margin-left: 0;
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
}

.block {
  display: flex;
  flex-direction: column;
  padding: 28px;
  border: 1px solid #d8dfec;
  border-radius: 16px;
  background-color: #fff;
  width: 35%;
  min-width: 320px;

  &:first-child {
    width: 480px;
  }

  &:last-child {
    width: 100%;
    margin-left: 32px;
  }

  &__item {
    padding-left: 28px;
    width: 50%;
    &:first-child {
      border-right: 1px solid #d8dfec;
      padding-right: 28px;
      padding-left: 0;
      position: relative;
      &:after,
      &:before {
        content: '';
        position: absolute;
        right: -1px;
        height: 28px;
        width: 1px;
        background-color: #d8dfec;
      }

      &:before {
        top: -28px;
      }

      &:after {
        bottom: -28px;
      }
    }
  }
}

div.mb20 {
  margin-bottom: 20px !important;
}
