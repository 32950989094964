@import "../../scss/_common/common";

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 420px;
  min-width: 360px;
}

.title_spec {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-bottom: 5px;
}

.conditions {
  &__wrapper {
    border: 1px solid #D8DFEC;
    background-color: #fff;
    padding: 26px;
    border-radius: 16px;
    width: 100%;

    & > div:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid #d8dfec;
    padding: 10px 0;
    margin: 10px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
}


.item_border_red {
  border: 1px solid #ffa9a3;
  border-radius: 20px;
}