.box-wrap-group {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);

  .box-wrap {
    box-shadow: none;
    border-bottom: 1px solid $color-white-lilac;

    &:last-child {
      border-bottom: none;
    }
  }
}

.box-wrap {
  position: relative;
  background: $color-white;
  box-shadow: 0 0 30px 0 rgba(82,63,105, .05);
  padding: rem($page-container_pad--h) rem($page-container_pad--v);
  border-radius: rem(4px);

  @media screen and (max-width: 1600px) {
    padding: rem($page-container_pad--h / 2) rem($page-container_pad--v / 2);
  }

  &--full-w {
    border-radius: 0;
  }

  &--py-sm {
    padding-top: rem($page-container_pad--h / 2);
    padding-bottom: rem($page-container_pad--h / 2);

    @media screen and (max-width: 1600px) {
      padding-top: rem($page-container_pad--h / 4);
      padding-bottom: rem($page-container_pad--h / 4);
    }
  }

  &--mb {
    margin-bottom: rem($page-container_pad--v);
  }
}

.box-wrap--client {
  font-weight: bold;
  @media screen and (max-width: 1600px) {
    padding: rem($page-container_pad--h / 2) rem($page-container_pad--v / 2);
  }

  &--full-w {
    border-radius: 0;
  }

  &--py-sm {
    padding-top: rem($page-container_pad--h / 2);
    padding-bottom: rem($page-container_pad--h / 2);

    @media screen and (max-width: 1600px) {
      padding-top: rem($page-container_pad--h / 4);
      padding-bottom: rem($page-container_pad--h / 4);
    }
  }

  &--mb {
    margin-bottom: rem($page-container_pad--v);
  }
}