@import "src/scss/_common/common";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  & > p {
    font-size: 12px;
    line-height: 14px;
    color: #da3b2f;
  }
}

.input__wrapper {
  position: relative;
}

input.input {
  padding: 18px 16px 4px 16px;
  height: 52px;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 12px;
  transition: border .2s ease;

  &::placeholder {
    opacity: 0;
  }

  &:focus + .label,
  &:not(:placeholder-shown) + .label,
  & ~ input + .label,
  &:focus + div + .label,
  &:not(:placeholder-shown) + div + .label,
  & ~ input + div + .label {
    transform: translateY(-100%) scale(.8);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    border-color: #a2b3c2;
  }

  &:focus {
    border-color: #000;
  }
  &:read-only,
  &:disabled {
    background: #edeef0;
    cursor: no-drop;
  }
}

input.input_error {
  color: #da3b2f;
  border-color: #ffa9a3;

  &:focus {
    border-color: #ffa9a3;
  }
}

.input_mt {
  margin-top: 24px;
}

input[type="date"].input,
input[type="time"].input {
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
    margin-bottom: 12px;
  }
}

input[type="date"].input {
  background-image: url("../../../assets/icons/icon_date.svg");
}

input[type="time"].input {
  background-image: url("../../../assets/icons/icon_clock.svg");
}

.label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 15px;
  line-height: 20px;
  color: #808080;
  transition: transform .2s ease;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: stretch;
  pointer-events: none;
}

.phone_info {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.phone_popup {
  background-color: #fff;
  border-radius: 8px;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
  padding: 12px;
  margin-left: 16px;
  position: absolute;
  right: 25px;
  bottom: 165px;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12));
  max-width: 165px;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    right: 15px;
    top: -5px;
    transform: rotate(135deg);
    width: 9px;
    height: 4px;
    background-color: #fff;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
}

.z_index_5 {
  z-index: 5;
  position: relative;
}

.z_index_6 {
  z-index: 6;
  position: relative;
}

input.sm {
  padding: 0.25rem 0.4rem;
  border-radius: 0.1rem;
  border: 0.05rem solid #bcc3ce;
  font-size: 0.6rem;
  height: 1.5rem;
}
.lg {}
