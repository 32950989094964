// Reset Default Buttons Styles
@mixin ex_res-btn {
	cursor: pointer;

	border: 0;
	border-radius: 0;
	outline: none;
	box-shadow: none;

	appearance: none;
}

%ex_res-btn {
	@include ex_res-btn;
}
