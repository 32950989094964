.Toastify__toast--default {
  color: initial;
}

.Toastify__toast {
  width: fit-content;
}

.__react_component_tooltip{
  max-width: 250px;
  word-wrap: break-word;
}