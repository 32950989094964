@import "../../scss/_common/common";

.wrap {
  display: flex;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1400px) {
    width: 100%;
    margin-bottom: 32px;
    margin-right: 0;
  }

  & > div {
    width: 100%;
    margin-right: 32px;
  }

  &__category {
    margin-bottom: 12px;
    div {
      margin-right: 0 !important;
    }
  }
}
