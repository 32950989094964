@import "../../../../scss/_common/common";

button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
}

.action_type {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & > div {
    &:not(:first-child) {
      margin-left: 16px;
    }
    min-width: 320px;
  }
}
