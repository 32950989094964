@import "src/scss/_common/common";

.wrap {
  margin: 0 auto;

  :global {
    .pagination {
      display: inline-flex;
    }
  }
}