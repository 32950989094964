@import "../../scss/_common/common";

.tree_wrap {
  overflow-y: auto;
  height: 700px;
  max-height: 1000px;
  position: relative;
}

.divider {
  margin: 0 0 30px 0 !important;
}
