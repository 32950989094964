@import "src/scss/_common/common";

.wrap {
  vertical-align: bottom;
  :global {
    th, td {
      position: relative;
      z-index: 1;
    }
  }
}

.wrapLoading {
  :global {
    th, td {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $color-white;
        opacity: 0.8;
        z-index: 4;
      }
    }
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
  margin-top: 5px;

  & > div {
    margin-bottom: auto;
  }
}

.baseline {
  vertical-align: baseline;
  padding: 0.4rem !important;

  &:first-child {
    padding-left: 1rem !important;
    @media screen and (max-width: 1600px) {
      padding-left: 0.2rem !important;
    }
  }

  & > div > div {
    margin-bottom: 0;
    min-width: 18px;
    min-height: 18px;
    justify-content: center;
    input[type=checkbox] {
      width: 18px;
      height: 18px;
    }
    //input[type=checkbox] + label {
    //  &:after,
    //  &:before {
    //    top: 0;
    //  }
    //}
  }
}

.no_wrap {
  white-space: nowrap;
}

.btn_filter {
  padding: 4px 8px !important;
  margin-bottom: 4px;
  display: none !important;

  @media screen and (max-width: 768px) {
    display: block !important;
  }
}