@import "../../scss/_common/common";

.rselect__wrapper {
  margin: 0 !important;
  & > div > div > div[class*=-control] {
    padding: 15px 16px;
  }

  & > div > div > div[class*=-menu] {
    z-index: 3;
  }

  &_title {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.block__img {
  display: block;
  max-width: max-content;
  max-height: 270px;

  & > div > div {
    max-width: 480px;
    max-height: 270px;
    width: 480px !important;
    height: 270px !important;

    img {
      max-height: 270px;
    }
  }
}

.tree {
  width: 100%;
  & > div {
    margin: 0 !important;
  }
}

.wrapper_search_input {
  width: 100%;
  max-width: 340px;
}