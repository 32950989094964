@import "../../scss/_common/common";

.top {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 370px;
  }

  &__rigth {
    max-width: 620px;

    @media screen and (max-width: 1830px) {
      max-width: 500px;
    }

    @media screen and (max-width: 1500px) {
      max-width: 450px;
    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
    }
  }
}

.date_block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 50%;
  }
}


.media {
  height: 100%;
  * {
    margin-bottom: 0 !important;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & > div > div {
    height: 120px !important;
  }
}

.sub_title {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 10px;
}

.img_block {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
