@use "imports/spectre-vars.import";


$fs-browser: 16px; // needed for breakpoints, $font-size, etc...


// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-steel-gray: #1e1e2d;
$color-white-lilac: #f9f9fb;

$color-primary: #5755d1;
//$color-success
//$color-error

$color-body: $color-white-lilac;
//$color-text;

// Typography
//$ff-base: 'TTCommons', serif;

//$fw-base--reg: 400;
//$fw-base--semi: 600;
//$fw-lead--bold: 700;

//$fs-h1: 28px;
//$fs-h2: 24px;
//$fs-h3: 20px;
//$fs-h4: 18px;
//$fs-h5: 15px;
//$fs-h6: 13px;


// Breakpoint system
$breakpoints: (
        xs: rem(spectre-vars.$size-xs, $fs-browser),
        sm: rem(spectre-vars.$size-sm, $fs-browser),
        md: rem(spectre-vars.$size-md, $fs-browser),
        lg: rem(spectre-vars.$size-lg, $fs-browser),
        xl: rem(spectre-vars.$size-xl, $fs-browser),
        xxl: rem(spectre-vars.$size-2x, $fs-browser)
);


// Grid
$page-container_pad--v: 25px;
$page-container_pad--h: 25px;

$drop-zone__size: 150px;
$drop-zone__size--sm: 80px;


// Transitions
$ts-base: 0.3s ease;
$ts-base--fs: 0.125s ease;