@import "./src/scss/_common/common";

.wrap {
  display: flex;
  align-items: center;

  .imgBox {
    margin-right: rem(20px);
  }

}

.sizeSm {
  .imgBox {
    $sz: 80px;

    width: rem($sz);
    height: rem($sz);
  }
}

.imgBox {
  $sz: $drop-zone__size;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem($sz);
  height: rem($sz);
  background: $color-body;

  > input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
  }

  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.imgBoxError {
  background: rgba(red, 0.35);
}


.inputHidden {
  @extend %ex_input-hidden;
}

.cropBox {
  :global {
    img {
      display: none;
    }

    .cropper-container {
      display: none;
    }
  }
}