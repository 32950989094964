@import "../../scss/_common/common";

.inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  @media screen and (max-width: 1181px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 801px) {
    grid-template-columns: 1fr;
  }
  form {
    width: 100%;
  }

  label {
    max-width: 100%;
  }
}

.block {
  display: flex;
  flex-direction: column;
  padding: 28px;
  border: 1px solid #d8dfec;
  border-radius: 16px;
  background-color: #fff;

  & > form.form-horizontal {
    padding: 0 !important;
  }

  &__item {
    padding-left: 28px;
    width: 50%;
    &:first-child {
      border-right: 1px solid #d8dfec;
      padding-right: 28px;
      padding-left: 0;
      position: relative;
      &:after,
      &:before {
        content: '';
        position: absolute;
        right: -1px;
        height: 28px;
        width: 1px;
        background-color: #d8dfec;
      }

      &:before {
        top: -28px;
      }

      &:after {
        bottom: -28px;
      }
    }
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-bottom: 20px;
}

.warehouse__radio {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  label {
    white-space: nowrap;
  }
}

.warehouse__block {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  position: relative;

  &:nth-child(2) {
    margin-bottom: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #d8dfec;
  }

  ul {
    list-style: none;
    overflow: auto;
    max-height: 275px;
    width: 100%;

    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      color: #000;

      div {
        margin-bottom: 8px;
      }
    }
  }
}

div.mb20 {
  margin-bottom: 20px !important;
}

.disabled {
  cursor: none;
  pointer-events: none;
  label {
    color: #828282;

    &:after,
    &:before {
      opacity: .5;
    }
  }
}
