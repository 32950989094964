@import "../../../../scss/_common/common";

.wrapper {
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 16px;
  width: 866px;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 32px;
  }
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #edeef0;
  border-bottom: 1px solid #d8dfec;
  border-radius: 16px 16px 0 0;
  padding: 20px 28px;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000;
  }

  &__open {
    background-color: transparent;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5755d1;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: #4644d5;
    }
    &:disabled {
      cursor: auto;
      opacity: 0.5;
    }
  }

  &__wrapper {
    margin: 20px 28px;
    overflow: auto;
    max-height: 900px;
    padding-right: 28px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    align-items: center;

    & > img {
      cursor: pointer;
      width: 14px;
      height: 14px;
      margin-left: 6px;
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      margin-bottom: 8px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__img {
    margin-right: 32px;

    & > img {
      max-width: 90px;
      max-height: 94px;
    }
  }
}

.categories {
  padding: 20px 28px;
  border-top: 1px solid #d8dfec;
}

.wrapper_radio {
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    margin-right: 14px;
    & > div {
      margin-left: 6px;
      height: 18px;
    }
  }
}

.input_icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.tree_wrap {
  overflow-y: auto;
  height: 700px;
  max-height: 1000px;
  position: relative;

  & div > ol > li > ol {
    margin-top: 0;
    &:after {
      display: none;
    }
  }
}