@import "src/scss/_common/common";

.btn {
  cursor: pointer;
  border-radius: 12px;
  padding: 10px 16px;
  border-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  & > img {
    padding-right: 8px;
  }

  & > a {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: unset;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: no-drop;
  }

  svg {
    margin-right: 8px;
    fill: #fff;
    rect {
      stroke: #5755d1;
      transition: all 0.3s;
    }
  }

  &:hover {
    & > a {
      color: #fff;
    }
    svg {
      fill: #5755d1;
      rect {
        stroke: #fff;
      }
    }
  }
}

.btn_full {
  width: 100%;
}

.btn_small {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 16px;
}

.btn_green {
  background-color: #34a853;

  &:hover {
    background-color: #4ab968;
  }

  &:focus,
  &:active {
    background-color: #269343;
  }
}

.btn_red {
  background-color: #da3b2f;

  &:hover {
    background-color: #db493f;

    svg {
      path {
        fill: #ffe0e0;
        transition: all 0.5s;
      }
    }
  }

  &:focus,
  &:active {
    background-color: #e73223;
  }
}

.btn_white {
  background-color: #fff;
  border-color: #5755d1;
  color: #5755d1;

  img {
    margin-left: 4px;
    padding: 0;
  }

  &:hover {
    background-color: #e9e9e9;
  }

  &:focus,
  &:active {
    background-color: #e9e9e9;
  }
}

.btn_purple {
  background-color: #5755d1;

  &:hover {
    background-color: #6f6ee7;
  }

  &:focus,
  &:active {
    background-color: #4240ac;
  }
}
.btn_border {
  color: #5755d1;
  background-color: transparent;
  border: 2px solid #5755d1;

  &:hover {
    background-color: #5755d1;
    color: #fff;

    svg {
      path {
        fill: #fff;
        transition: all 0.5s;
      }
    }
  }

  &:focus,
  &:active {
    background-color: #4240ac;
    color: #fff;
  }
}

.btn_border_clear {
  color: #5755d1;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgb(66, 64, 172);

    svg {
      path {
        fill: rgb(38, 37, 142);
        transition: all 0.5s;
      }
    }
  }

  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    color: #5755d1;
  }
}

.btn_border_radius {
  border-radius: 50%;
  padding: 5px 7px 7px 7px;
  line-height: 1;
}

.btn_link {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #5755D1 !important;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;

  &:hover {
    color: #6f6ee7 !important;
    text-decoration: underline;
  }

  &:focus,
  &:active {
    color: #4240ac !important;
  }
}

button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  width: 24px !important;
}