@use "../_common/imports/spectre-vars.import";

.sortable-ghost {
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: linear-gradient(
                    rgba(white, 0.5),
                    rgba(white, 0.5)
    ), spectre-vars.$primary-color;
  }
}

.sortable-drag-hidden {
  opacity: 0;
}