@import "../../../scss/_common/common";

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 40px;

  & > div {
    &:first-child {
      margin-right: 12px;
    }
  }

  img {
    cursor: pointer;
    display: block;
  }
}

.breadcrumbs__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.breadcrumbs {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;

  & > a {
    color: #000000 !important;
    opacity: 0.55;
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
  }

  span {
    margin-left: 8px;
    opacity: 1;
    color: #000000;
  }
}

.order_edited {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_edited__block {
  margin-right: 40px;
  text-align: center;
}

.order_edited__box {
  position: relative;

  & > button {
    min-width: 205px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.order_edited__title {
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}

.order_edited__date {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

button.btn__status {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  background-color: #22a4cd;
  border-radius: 52px;
  border-color: transparent;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 135px;

  &__disable {
    background-color: #95a4b8;
    cursor: no-drop;
  }

  img {
    display: block;
    padding-left: 16px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  position: sticky;
  top: 60px;
  z-index: 9;
  background-color: #f9f9fb;
  padding: 10px 0;

  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5755D1 !important;
    display: flex;
    align-items: center;

    & > img {
      padding-right: 10px;
    }
  }
}

.buttons__wrapper {
  display: flex;
  align-items: center;
}

.buttons__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button__block {
  margin-right: 12px;
}

.button__open {
  background-color: #fff;
  border: 2px solid #5755d1;
  padding: 12px 10px;
  border-radius: 12px;
  cursor: pointer;

  & > img {
    display: block;
    transition: all .3s;
  }
}

.button__box {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.buttons__show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 0;
  opacity: 0;
  right: 0;
  overflow: hidden;
  transition: all .5s;
  visibility: hidden;

  & > div {
    margin-right: 12px;
  }
}

.active_buttons {
  position: relative;
  z-index: 1;
  opacity: 1;
  right: 0;
  border-left: 1px solid #5755d1;
  padding-left: 16px;
  visibility: visible;
}

.active_arrow {
  transform: rotate(180deg);
}

.copy {
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding: 12px;
  margin-left: 16px;
  position: relative;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12));

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
    width: 9px;
    height: 4px;
    background-color: #fff;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
}

.order_status {
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.24);
  border-radius: 18px;
  padding: 12px 16px;
  position: absolute;
  top: 50px;
  left: -60px;
  min-width: 200px;
  z-index: 10;

  & > div {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: underline;
      color: #e89a03;
    }
  }
}

.order_status_close {
  position: absolute;
  background-color: transparent;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

.no_edit {
  background: rgba(218, 59, 47, 0.06);
  border: 1px solid rgba(218, 59, 47, 0.32);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: center;

  &__item {
    position: relative;

    &:not(:first-child) {
      padding-left: 20px;
    }
    &:not(:last-child) {
      padding-right: 20px;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 20px;
        background: rgba(218, 59, 47, 0.32);
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #da3b2f;
    & > img {
      margin-right: 10px;
    }
  }
}