@import "./src/scss/_common/common";

.tab {
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;

  @media screen and (max-width: 1600px) {
    margin-bottom: 20px;
    margin-right: 0;
    gap: 10px;
  }

  &__item {
    margin: 0;
    &:not(:last-child) {
      margin-right: 36px;
    }

    & > a {
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      white-space: nowrap;

      &:hover,
      &:visited {
        color: #000;
      }
    }

    &.tab__line {
      & > a {
        color: #5755d1;
        &:visited {
          color: #5755d1;
        }
      }
    }
  }

  &__line {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: calc(100% + 2px);
      height: 6px;
      background: radial-gradient(56.45% 50% at 50% 50%,#5755d1 7.81%,rgba(87,85,209,.354167) 60.42%,hsla(0,0%,100%,0) 100%);
    }
  }
}