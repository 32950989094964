@import "src/scss/_common/common";

.wrap {
  margin: 0 0 rem(30px) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;
  top: 60px;
  z-index: 9;
  background-color: #f9f9fb;
  padding: 10px 0;

  @media screen and (max-width: 1600px) {
    top: 48px;
  }

  &_second_sticky {
    top: 120px;

    @media screen and (max-width: 1600px) {
      top: 108px;
    }
  }
}

.col {
  display: flex;
  align-items: center;
}