@import "src/scss/_common/common";

.btn_block {
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    &:first-child {
      margin-right: 20px;
    }
  }
}