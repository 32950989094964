@import "src/scss/_common/common";

.group_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    button {
      margin-right: 5px;
    }
    div {
      margin-right: 5px;
    }
  }
}

.conditions__block {
  display: flex;
  border-bottom: 1px solid #d8dfec;
  padding: 10px 0;
  margin: 10px 0;

  & > div {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  select {
    margin-left: 0 !important;
  }
}

.input__wrapper {
  position: relative;
  text-align: center;
}

.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file_button_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 1px;
}

.input__file_button {
  cursor: pointer;
  border-radius: 12px;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  width: 100%;
  background-color: #fff;
  color: #5755d1;
  border: 2px solid #5755d1;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      margin-right: 8px;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:focus,
  &:active {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.date__block {
  display: flex;
  align-items: center;
  & > span {
    margin: 0 10px;
  }
}

.promo__table {
  & > div {
    margin: 0;
  }
}

.disabled {
  cursor: none;
  pointer-events: none;
  label {
    color: #828282;

    &:after,
    &:before {
      opacity: .5;
    }
  }
}

.top {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1441px) {
    flex-wrap: wrap;
  }

  &__left {
    width: 420px;
    margin-right: 32px;
  }

  &__rigth {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-right: 32px;
    min-width: 360px;

    @media screen and (max-width: 1441px) {
      margin-right: 0;
      margin-top: 32px;
    }

    @media screen and (max-width: 700px) {
      min-width: auto;
    }
  }

  &__img_block {
    margin-right: 32px;
    width: 100%;

    @media screen and (max-width: 1441px) {
      margin-right: 0;
      margin-top: 32px;
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 900px) {
      margin-right: 0;
    }
  }
}

.block {
  display: flex;
  justify-content: space-between;

  &__item {
    border: 1px solid #d8dfec;
    border-radius: 16px;
    overflow: hidden;
    width: 50%;
    background-color: #fff;

    &:first-child {
      margin-right: 32px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    padding: 20px 28px;
    background-color: #edeef0;
    border-bottom: 1px solid #d8dfec;
  }

  &__content {
    display: flex;
    margin: 28px;
    align-items: flex-start;

    @media screen and (max-width: 1550px) {
      flex-direction: column;
    }
  }

  &__show {
    background-color: #f2f0ef;
    border-radius: 20px;
    padding: 32px;
    color: #000;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 1550px) {
      min-width: 360px;
    }

    &_title {
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &_subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 8px;
      bottom: 8px;

      & > span {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        margin-right: 8px;
      }
    }
  }

  &__info {
    width: calc(100% - 392px);
    margin-right: 32px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}

.right_block {
  padding: 28px;
  border-radius: 16px;
  border: 1px solid #d8dfec;
  background-color: #fff;
}
