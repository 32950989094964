@import "../../scss/_common/common";

.bank__image {
    transition: none !important;
    transform: none !important;
    width: 30px;
    height: 30px;

    &:hover {
        transform: none;
    }
}