@import "src/scss/_common/common";

.margin0 {
  margin: 0!important;
}

.block {
  display: flex;
  padding: 28px;
  border: 1px solid #d8dfec;
  border-radius: 16px;
  background-color: #fff;

  & > form.form-horizontal {
    padding: 0 !important;
  }

  &:first-child {
    width: 480px;
  }

  &:last-child {
    width: 100%;
    margin-left: 32px;
  }

  &__item {
    padding-left: 28px;
    width: 100%;
    &:first-child {
      border-right: 1px solid #d8dfec;
      padding-right: 28px;
      padding-left: 0;
      position: relative;
      &:after,
      &:before {
        content: '';
        position: absolute;
        right: -1px;
        height: 28px;
        width: 1px;
        background-color: #d8dfec;
      }

      &:before {
        top: -28px;
      }

      &:after {
        bottom: -28px;
      }
    }
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-bottom: 20px;
}

div.mb20 {
  margin-bottom: 20px !important;
}

.label_block {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  position: relative;
  margin-bottom: 8px;
  display: flex;

  & > input:indeterminate + label:after {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background: #5755d1;
    left: 4px;
    opacity: 1;
  }
}

.input_icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
}

.tree_wrap {
  overflow-y: auto;
  height: 700px;
  max-height: 1000px;
  position: relative;

  & > ul {
    margin: 0;
  }
}

.tree_wrap_category {
  overflow-y: auto;
  height: 700px;
  max-height: 1000px;
  position: relative;

  & > ul {
    margin: 0;

    & > ul {
      margin: 0 0 16px 0;
    }
  }
}

.tree_wrap_product {
  overflow-y: auto;
  height: 600px;
  max-height: 900px;
  position: relative;

  & > ul {
    margin: 0;

    & > ul {
      margin: 0 0 16px 0;
    }
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -2;
}


.category {
  &__wrapper {
    list-style-type: none;
  }
  &__text {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;

    &_active {
      color: #34a853;
      font-weight: 700;
    }
  }
}

.input_hidden {
  &:after {
    background-image: url("../../../assets/icons/icon_indeterminate.svg");
  }
}

.select_top {
  height: 18px;

  & > label {
    &:before,
    &:after {
      top: 0;
    }
  }
}