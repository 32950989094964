@import "src/scss/_common/common";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;
}

.active {
  opacity: 1;
  z-index: 99999;
  pointer-events: all;
}

.modal__content {
  padding: 32px;
  margin: 32px;
  border-radius: 20px;
  background-color: #fff;
  max-width: 1152px;
  min-width: 575px;
}

.modal__header {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }
}

.modal__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}

.modal__box {
  overflow: auto;
  max-height: 50vh;
}

.modal__group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  & > div {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.modal__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > div {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.modal__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-right: 23px;
}

.modal__update {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #5755d1;
  cursor: pointer;

  & > img {
    margin-right: 8px;
  }
}

.modal__lock {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #da3b2f;
  cursor: pointer;

  & > img {
    margin-right: 8px;
  }
}