@import "src/scss/_common/common";


.wrap {
  font-size: rem(12px);
  margin-left: rem($page-container_pad--h) * -1;
  margin-right: rem($page-container_pad--h) * -1;
  overflow: auto;

  @media screen and (max-width: 1600px) {
    margin-left: rem($page-container_pad--h) * -0.5;
    margin-right: rem($page-container_pad--h) * -0.5;
  }

  :global {
    .form-input {
      font-size: rem(12px);
      height: rem(30px);
    }
  }
}



table.table {
  border-collapse: inherit;
  :global {
    thead {
      background-color: #fff;
    }

    tr > th,
    tr > td {
      list-style: none;
      border-right: 0.05rem solid #dadee4;
      &:first-child {
        padding-left: rem(20px);
        @media screen and (max-width: 1600px) {
          padding-left: rem(4px);
        }
      }

      &:last-child {
        padding-right: rem(20px);
        @media screen and (max-width: 1600px) {
          padding-left: rem(4px);
        }
      }
      @media screen and (max-width: 1600px) {
        padding: rem(4px);
        max-width: 50px;
        width: auto;
      }
      @media screen and (max-width: 1800px) {
        max-width: 100px;
      }
      word-wrap: break-word;
    }

    tr {
      > td {
        img {
          $sz: 50px;

          font-size: rem($sz);
          width: 1em;
          height: 1em;
          object-fit: cover;
          transition: transform 0.2s;

          &:hover {
            transform: scale(2);
          }
        }
      }
    }

    .btn-group {
      flex-wrap: nowrap;
    }

    // @todo: move to controls component
    .table-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }
}