@import "./src/scss/_common/common";

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000;
}

.list__wrapper {
  display: flex;
  align-items: center;
  position: relative;

  ul {
    list-style: none;
    overflow: auto;
    width: 100%;
    margin: 13px 0;

    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      color: #000;

      div {
        margin-bottom: 8px;
      }
    }
  }
}