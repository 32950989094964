@import "./src/scss/_common/common";

.wrap {
  display: flex;
  align-items: center;
}

.sizeSm {
  .imgBox {
    $sz: 80px;

    width: rem($sz);
    height: rem($sz);
  }
}

.imgBox {
  $sz: $drop-zone__size;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 2px dashed #5755D1;
  border-radius: 12px;

  > input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
  }

  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__no_border {
    border: none;
  }
}

.imgBoxError {
  background: rgba(red, 0.35);
}


.inputHidden {
  @extend %ex_input-hidden;
}

.cropBox {
  :global {
    img {
      display: none;
    }

    .cropper-container {
      display: none;
    }
  }
}

.add_img {
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 20px;
    height: 20px;
  }

  &__text {
    margin-left: 8px;
    color: #5755D1;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__size {
    position: absolute;
    bottom: 16px;
    color: #828282;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.button_delete {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  & > button {
    border-radius: 50%;
    background-color: #fff;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px !important;
  }
}

.bottom_text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #828282;
}