@import "../../scss/_common/common";

.top {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.role_item {
  border: 2px solid #D8DFEC;
  border-radius: 20px;
  max-width: max-content;
  min-width: 380px;
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid transparent;
    border-radius: 20px;
    gap: 16px;
    cursor: pointer;
    padding: 16px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
  }

  &__img_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    & > button {
      display: flex;
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 16px;
    font-size: 15px;
    font-weight: 600;
  }
}

.active_block {
  border: 2px solid #5755D1;
  color: #5755D1;
  background-color: rgba(87, 85, 209, 0.1);
}
