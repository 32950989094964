@import "src/scss/_common/common";

.order-info_list{
  margin: 0;
  li {
    padding: 8px 20px;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-child(even){
      background-color: #f9f9fa;
    }
    &.flex-column{
      flex-direction: column;
    }

    & > div {
      font-size: 14px;
      line-height: 20px;
      color: #808080;
      width: 50%;
    }

    & > strong {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      width: 50%;
      word-break: break-word;
    }
  }
}

