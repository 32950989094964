@import "../../scss/_common/common";

.wrap {
  display: flex;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
}

.left {
  margin-right: 20px;
  width: 870px;

  @media screen and (max-width: 1800px) {
    width: 570px;
  }

  @media screen and (max-width: 1400px) {
    width: 100%;
    margin-bottom: 32px;
    margin-right: 0;
  }



  &__header {
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    & > div {
      width: 100%;
      &:first-child {
        margin-right: 32px;
      }
    }
  }

  &__block {
    background-color: #fff;
    border: 1px solid #d8dfec;
    border-radius: 16px;
    padding: 28px;

    & > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-right: 32px;
    min-width: 416px;

    @media screen and (max-width: 1200px) {
      min-width: auto;
    }
  }
}

.show {
  width: 360px;
  background-color: #f2f0ef;
  border-radius: 20px;
  padding: 32px 48px;
  color: #000;

  &__full {
    width: 750px;
    display: flex;
    align-items: center;
    padding: 32px 60px;

    .show__title {
      margin-bottom: 8px;
    }

    .show__desc {
      margin-bottom: 20px;
    }

    .show__price {
      margin-bottom: 8px;
    }

    .show__img {
      margin: 0 15px 0 0;
      min-width: 50%;
      height: 345px;
    }
  }

  &__img {
    width: 100%;
    height: 210px;
    max-width: none;
    margin: 0 auto 12px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  &__desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  &__price {
    font-weight: 700;
    font-size: 19px;
    line-height: 25px;

    &_small {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__mounth {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    & > span {
      margin-right: 4px;
    }
  }

  &__credit {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & > div {
      display: flex;
      align-items: center;
      margin-right: 12px;

      & > img {
        margin-right: 4px;
      }
    }
  }

  &__btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 11px 34px;
    color: #fff;
    background: #1428a0;
    border-radius: 22px;
    width: max-content;
  }
}