@import "src/scss/_common/common";


.wrap {
  margin: rem(40px) 0 0;
}

.btnWrap {
  text-align: right;
  margin: rem(20px) 0 0;
}