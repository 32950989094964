@import "../../../scss/_common/common";

.slider_black {

  & > span[class*="MuiSlider-track"],
  & > span[class*="MuiSlider-rail"] {
    color: #000
  }

  & > span[class*="MuiSlider-thumb"] {
    background-image: url("../../../assets/icons/icon_circle_black.svg");
    width: 21px;
    height: 21px;

    & > span[class*="MuiSlider-valueLabelOpen"] {
      transform: translateY(100%) scale(1);
      background-color: transparent;
      padding-top: 2px;
      padding-bottom: 0;

      span[class*="MuiSlider-valueLabelLabel"] {
        font-size: 15px;
        font-weight: 700;
        color: black;
      }
    }
  }
}