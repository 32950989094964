.order_item{
  border: 1px solid #d8dfec;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  -webkit-column-break-inside: avoid;
  filter: drop-shadow(0 4px 32px rgba(0, 0, 0, 0.09));
}

.order_item_header{
  color: black;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  border-bottom: 1px solid #d8dfec;
  background-color: #edeef0;
  box-sizing: border-box;
  border-radius: 16px 16px 0 0;
}

.order_item_main{
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;

  & > form {
    padding: 0 20px;
  }
}

.disable_block {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: no-drop;
    z-index: 4;
  }
}
