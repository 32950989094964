@import "src/scss/_common/common";

.topPanel {
  margin: 40px 40px 0 40px;
  @media screen and (max-width: 1600px) {
    margin: 20px 12px 0 12px;
  }

  &_sticky {
    position: sticky;
    top: 60px;
    background-color: #f9f9fb;
    z-index: 2;

    @media screen and (max-width: 1600px) {
      top: 48px;
    }
  }
}

.breadcrumbs__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.breadcrumbs {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;

  & > a {
    color: #000000 !important;
    opacity: 0.55;
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }
  }

  span {
    margin-left: 8px;
    opacity: 1;
    color: #000000;
  }
}

.footerNew {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;

  & .buttons__group {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5755D1 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;

  & div {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5755D1 !important;
    display: flex;
    align-items: center;
  }
}


.footer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: flex-end;

  & .buttons__group {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5755D1 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

}

.active_link {
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -9px;
    height: 2px; /* Толщина подчёркивания */
    background: linear-gradient(to right, rgba(87, 85, 209, 0), rgba(87, 85, 209, 1), rgba(87, 85, 209, 0));
  }
}

/*  Title */
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 40px;

  & > div {
    &:first-child {
      margin-right: 12px;
    }
  }

  img {
    cursor: pointer;
    display: block;
  }
}


/*   order_edited  */
.order_edited {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_edited__block {
  text-align: center;
}

.order_edited__title {
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}

.order_edited__date {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

/*   button   */
.buttons__wrapper {
  display: flex;
  align-items: center;
  gap: 28px;
  justify-content: flex-end;
  width: 100%;
}

.buttons__group {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.button__open {
  background-color: #fff;
  border: 2px solid #5755d1;
  padding: 12px 10px;
  border-radius: 12px;
  cursor: pointer;

  & > img {
    display: block;
    transition: all .3s;
  }
}

.button__box {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.buttons__show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 0;
  opacity: 0;
  right: -100%;
  overflow: hidden;
  transition: all .5s;

  & > div {
    margin-right: 12px;
  }
}

.active_buttons {
  position: relative;
  z-index: 1;
  opacity: 1;
  right: 0;
  border-left: 1px solid #5755d1;
  padding-left: 16px;
}

.active_arrow {
  transform: rotate(180deg);
}

.copy {
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding: 12px;
  margin-left: 16px;
  position: relative;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12));

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
    width: 9px;
    height: 4px;
    background-color: #fff;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
}

.button {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  &__block {
  }
}

.button__remove {
  position: relative;
  padding-left: 22px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 24px;
    background: #5755d1;
    opacity: 0.2;
    border-radius: 2px;
  }
}

a.display {
  display: flex;
  align-items: center;
  color: #fff;

  img {
    margin-right: 6px;
  }
}


.top_info {
  display: flex;
  border-radius: 16px;
  border: 1px solid #D8DFEC;
  background-color: #FFF;
  padding: 13px 20px;

  &__item {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background-color: #D8DFEC;
    }

    &:first-child {
      padding-right: 16px;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      padding-left: 16px;

      &:before {
        display: none;
      }
    }
  }

  &__title {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
  }

  &__value {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.nav_back_wrapper {
  width: 100%;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
