/* # Base
   ========================================================================== */
// page
html {
  font-size: $fs-root / $fs-browser * 100%;
  line-height: $lh-root;
  box-sizing: border-box;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-font-variant-ligatures: none;
  /* Font varient */
  font-variant-ligatures: none;
  -moz-osx-font-smoothing: grayscale;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  /* Adjust font size */
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  //font-family: $ff-base;
  //font-weight: $fw-base--reg;
  //line-height: $lh-base;
  //font-size: 1em;
  font-size: $fs-body / $fs-root * 1em;
  position: relative;
  overflow-x: hidden;
  counter-reset: item;
  //color: $color-text;
  background-color: $color-body;
}

.is-warn{
  border: 1px solid orange;
}

.is-error-label {
  color: red;
}
// titles
//h1, .h1,
//h2, .h2,
//h3, .h3,
//h4, .h4,
//h5, .h5,
//h6, .h6{
//  font-weight: inherit;
//  line-height: $lh-base;
//
//  margin-top: vr(1);
//  margin-bottom: vr(1);
//}
//
//h1, .h1 {
//  font-size: rem($fs-h1);
//}
//
//h2, .h2 {
//  font-size: rem($fs-h2);
//}
//
//h3, .h3 {
//  font-size: rem($fs-h3);
//}
//
//h4, .h4 {
//  font-size: rem($fs-h4);
//}
//
//h5, .h5 {
//  font-size: rem($fs-h5);
//}
//
//h6, .h6 {
//  font-size: rem($fs-h6);
//}


// paragraph
//p {
//  margin-bottom: vr(1);
//}

//.p-lead {
//  font-size: rem($fs-lead);
//  line-height: $lh-lead;
//}

//small,
//.p-small {
//  font-size: rem($fs-sm);
//  line-height: $lh-sm;
//}


// list
//ul,
//ol {
//  margin-top: vr(1);
//  margin-bottom: vr(1);
//}


// links
//a {
//  color: $color-lead;
//  text-decoration: none;
//  transition: all $ts-base;
//
//  &:hover,
//  &:focus {
//    text-decoration: none;
//  }
//}


// images
img {
  max-width: 100%;
}


// forms
button,
input,
optgroup,
select,
textarea {
  //font-family: inherit;
  //line-height: inherit;
}

textarea {
  resize: vertical;
}

div.max-width {
  max-width: 100% !important;
}

// react-select
div.react-select {
  &__menu {
    z-index: 2;
  }

  &__control {
    padding: 5px 0 0 6px;
    min-height: 52px;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    border: 1px solid #d8dfec;
    border-radius: 12px;
  }

  &__single-value {
    white-space: normal;
    margin-top: 10px;
    margin-bottom: 6px;
  }
}

div.react_select__wrapper_error {
  & > div {
    & > div.react-select__control {
      color: #da3b2f;
      border-color: #ffa9a3;
    }

    & ~ p {
      color: #da3b2f;
      border-color: #ffa9a3;
    }
  }
}

#gjs,
#gjs-ua {
  border: 3px solid #444;

  .column-actions {
    display: none;
  }
  .row-actions {
    display: none;
  }
}

.gjs-block-category:first-child {
  & > .gjs-blocks-c {
    & > div:nth-child(3),
    & > div:nth-child(4),
    & > div:nth-child(5),
    & > div:nth-child(6),
    & > div:nth-child(7) {
      display: none;
    }
  }
}

/* Reset some default styling */

.gjs-am-assets {
  height: 240px;
}

.gjs-pn-views-container {
  width: auto !important;
  max-width: 15%;
}
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}
.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}
.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}
.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 300px;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}
.panel__switcher {
  position: initial;
}
.panel__devices {
  position: initial;
}

.gjs-block-label {
  .fa {
    font-size: 25px;
  }
}

.gjs-cv-canvas {
  width: 100%;
  height: 100%;
}

.tab-content {
  display: contents;
}

#block {
  height: 100%;
  #blocks {
    height: 100%;

    .gjs-blocks-c {
      align-items: center;
      justify-content: center;
    }
    .gjs-block {
      justify-content: center;
    }
  }
}

/* Theming */
.gjs-one-bg {
  background-color: #fcf6f5ff;
}

.gjs-two-color {
  color: #990011ff;
}

.gjs-three-bg {
  background-color: #990011ff;
  color: #fcf6f5ff;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: #990011ff;
}

iframe.gjs-frame {
  bottom: auto !important;
  top: 60px !important;
  margin: 0 auto 0 10px !important;
  max-width: 1530px !important;
  height: 92vh;
}

.gjs-pn-btn {
  border: 1px solid #990011ff;
}

.gjs-block-label {
  .fa {
    font-size: 25px;
  }
}

.gjs-pn-options {
  right: 20%;
}

.gjs-pn-views {
  width: 150px;
}

.grp-wrapper {
  background-image: url("data:image/png:base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}
.grp-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.grp-handler {
  width: 4px;
  margin-left: -2px;
  user-select: none;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.grp-handler-close {
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0 2px 10px 10px;
  background-color: #fff;
  text-align: center;
  width: 15px;
  height: 15px;
  margin-left: -5px;
  line-height: 10px;
  font-size: 21px;
  cursor: pointer;
}

.grp-handler-close {
  position: absolute;
  top: -17px;
}

.grp-handler-drag {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  width: 100%;
  height: 100%;
}

.grp-handler-selected .grap-handler-drag {
  background-color: rgba(255, 255, 255, 0.5);
}

.grp-handler-cp-c {
  display: none;
}

.grp-handler-selected .grp-handler-cp-c {
  display: block;
}

.grp-handler-cp-wrap {
  width: 15px;
  height: 15px;
  margin-left: -8px;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
}

.grp-handler-cp-wrap input[type="color"] {
  opacity: 0;
  cursor: pointer;
}

div.gjs-pn-buttons {
  justify-content: flex-start;
}

// sortable

.sortable-chosen {
  cursor: grabbing;
}

.justify-content-center {
  justify-content: center;
}

.react-datepicker-popper {
  left: -27px !important;
}

/* MuiTreeItem */

.MuiTreeItem-label {
  font-size: 16px !important;
}

/* react-pagination */

.pagination {
  margin: 0 40px 0 0;
  padding: 0;
  list-style: none;
  display: flex;

  & > .page_item {
    margin: 0 10px 0 0;

    &.page_previous {
      display: none;
    }

    &.page_next {
      display: none;
    }

    &.active {
      border: 2px solid #5755d1;
      border-radius: 6px;

      & > .page_link {
        background-color: transparent;
        color: #5755d1;
      }
    }

    & > .page_link {
      background-color: transparent;
      padding: 1px 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* tiny cloud */

.tox.tox-tinymce.tox-platform-touch {
  border-radius: 12px;
  padding: 3px;
}

/* scroll bar  */

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #d8dfec;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #a2b3c2;
  border-radius: 2px;
}


/* Table no sticky */

.table_no_sticky {
  table {
    thead > tr > th {
      &:last-child {
        right: auto;
      }
    }

    tbody > tr > td {
      &:last-child {
        position: static;
        right: 0;
      }
    }
  }
}

