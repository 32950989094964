@import "../../scss/_common/common";


.main {
  &__input {
    margin-right: 32px;
    min-width: 416px;

    @media screen and (max-width: 1200px) {
      min-width: auto;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: 50%;
    }
  }
}

.tab {
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  list-style: none;

  @media screen and (max-width: 1600px) {
    margin-bottom: 20px;
    margin-right: 0;
  }

  &__item {
    margin: 0;
    &:not(:last-child) {
      margin-right: 36px;
    }

    & > div {
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      white-space: nowrap;
      cursor: pointer;

      &:hover,
      &:visited {
        color: #000;
      }
    }

    &.tab__line {
      & > div {
        color: #5755d1;
        &:visited {
          color: #5755d1;
        }
      }
    }
  }

  &__line {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 4px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: calc(100% + 2px);
      height: 6px;
      background: radial-gradient(56.45% 50% at 50% 50%,#5755d1 7.81%,rgba(87,85,209,.354167) 60.42%,hsla(0,0%,100%,0) 100%);
    }
  }
}

.cat {
  &__block {
    padding: 28px;
    background: #fff;
    border: 1px solid #d8dfec;
    border-radius: 16px;
  }

  &__list {
    max-height: 500px;
    overflow: auto;

    ul {
      list-style: none;
      margin: 0;
      margin-right: 26px;

      li {
        cursor: pointer;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000;
      }
    }
  }
}

.tabs {
  &__wrapper {
    margin-top: 32px;
    display: flex;
    color: #a2b3c2;
  }

  &__text {
    margin-bottom: 20px;
    & > span {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #000;
      cursor: pointer;

      &:hover {
        color: #5755d1 !important;
      }

      &:first-child {
        margin-right: 12px;
      }
      &:last-child {
        margin-left: 12px;
      }
    }
  }
  &__active {
    color: #5755d1 !important;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-bottom: 20px;
}

.listA {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #828282;

    &_price {
      display: flex;
      margin: 0 10px;
    }

    &_clear {
      color: #da3b2f;
      cursor: pointer;
    }
  }

  &__body {

  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000;

    svg {
      g {
        path {
          fill: #da3b2f;
        }
      }
      fill: #da3b2f !important;
      overflow: visible !important;
    }
  }

  &__input {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    border: 1px solid #a2b3c2;
    border-radius: 6px;
    width: 80px;
  }
}

.discount__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;

  &:checked {
    & + .label:before {
      background-color: #5755d1;
    }
    & + .label:after {
      opacity: 1;
    }
  }

  &:disabled {
    & + .label {
      cursor: no-drop;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.label {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-left: 25px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all .2s;
  }

  &:before {
    border: 1px solid #5755d1;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
  }

  &:after {
    opacity: 0;
    background-image: url("../../assets/icons/icon_checkbox.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    color: #fff;
    z-index: 2;
  }

  &_disable {
    &:before,
    &:after {
      opacity: 0.5;
    }
  }
}

.checkbox_list__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.overflowHidden {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  left: -100%;
  top: -100%;
  width: 0;
  height: 0;

  label {
    transition: all 0s;
    &:after,
    &:before {
      transition: all 0s;
    }
  }
}


.rselect__wrapper {
  & > div > div > div[class*=-control] {
    padding: 15px 16px;
  }

  & > div > div > div[class*=-menu] {
    z-index: 3;
  }
}

.tabs__search {
    display: flex;
    gap: 12px;
    width: 100%;
    cursor: pointer;

    & div {
        width: 50%;
    }

    & .buttonWrapper {
        height: 52px;
        background: #5755d1;
        color: #fff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
}