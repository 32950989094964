@import "src/scss/_common/common";

.wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  line-height: 3;
  border-radius: .25em;
  width: 100%;
}

textarea.textarea {
  padding: 18px 16px 4px 16px;
  min-height: 132px;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 12px;
  transition: border .2s ease;

  &::placeholder {
    color: transparent
  }

  &:focus + .label__textarea,
  &:not(:placeholder-shown) + .label__textarea {
    transform: translateY(-100%) scale(.8);
    top: 25px;
  }

  &:hover {
    border-color: #a2b3c2;
  }

  &:focus {
    border-color: #000;
  }
}

.label__textarea {
  position: absolute;
  top: 19px;
  left: 16px;
  font-size: 15px;
  line-height: 20px;
  color: #808080;
  transition: transform .2s ease;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  pointer-events: none;
}