@import "./src/scss/_common/common";

.wrap {
  display: flex;
  align-items: center;
}

.box {
  position: relative;
  display: inline-block;
  z-index: 1;

  > input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
  }
}

.boxVal {
  > button {
    opacity: 0;
  }
}

.btnHidden {
  opacity: 0;
  visibility: hidden;
}

.inputHidden {
  @extend %ex_input-hidden;
}

.btn_remove {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: max-content;
  }
}