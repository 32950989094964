@import "src/scss/_common/common";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;

  table {
    & > thead > tr > th,
    & > tbody > tr > td {
      &:nth-child(even),
      &:nth-child(odd) {
        width: auto;
      }
    }
  }

  &__content {
    padding: 32px;
    margin: 32px;
    border-radius: 20px;
    background-color: #fff;
    max-width: 570px;
  }

  &__header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  &__box {
    overflow: auto;
    max-height: 65vh;
    padding-right: 10px;

    & > div {
      filter: none !important;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    & > div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 17px;
    line-height: 33px;
    color: #000000;
  }
}

.active {
  opacity: 1;
  z-index: 99999;
  pointer-events: all;
}