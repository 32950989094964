@import "src/scss/_common/common";


.trLoad {
  opacity: 0.5;
  pointer-events: none;
}

.trDisabled {
  pointer-events: none;

  &:first-child {
    td {
      padding-top: rem(100px);
    }
  }

  :global {
    td {
      position: relative;
      z-index: 1;
      border: 0;
      padding: 0;
    }

    .table-controls {
      position: relative;
      z-index: 1;
      visibility: hidden;
      pointer-events: none;
      height: 0;
    }
  }
}

button.btn_danger {
  border-color: #da3b2f;
  margin-left: 10px;

  &:hover {
    border-color: #c2332a;
  }

  &:active,
  &:focus {
    border-color: #ff3225;
    background-color: #fbefee;
  }

  & > i {
    color: #da3b2f;
  }
}