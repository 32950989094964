@import "src/scss/_common/common";

.select_input__container {
  position: relative;
}

.select_input__labels {
}

.select_input__search {
  position: absolute;
  z-index: 99;
  background: #fff;
  max-height: 132px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  border: .5px solid #ccc;
}

.select_input__search__item {
  padding: 10px 5px;
  width: 100%;
  border-bottom: .5px solid #ccc;

  &:hover {
    cursor: pointer;
  }
}

.select_input__label__item{
  display: inline-flex;
  align-items: center;
  margin: 5px;
  span {
    margin-right: 5px;
  }
}