@import "src/scss/_common/common";

.list {
  @extend %ex_list-unstyled;
}

.item {
  margin: 0;
}

.link {
  //@extend %ex_res-btn;
  //
  //position: relative;
  //z-index: 1;
  //display: block;
  //width: 100%;
  //padding: rem(10px) rem(10px) rem(10px) rem(30px);
  //text-align: left;
  //background: transparent;
  //
  //color: #000;
  //font-size: 16px;
  //font-weight: 600;
  //line-height: normal;
  //
  //span {
  //  position: absolute;
  //  z-index: 4;
  //  top: 50%;
  //  font-size: rem(10px);
  //  width: 1em;
  //  height: 1em;
  //  border-radius: 2em;
  //  margin-top: -0.5em;
  //  margin-left: rem(4px);
  //}
  //
  //&:hover,
  //&:focus {
  //  text-decoration: none;
  //}
}

.tab {
  list-style: none;
  margin: 0;

  &__item {
    margin: 0;
    &:not(:first-child) {
      margin-top: 12px;
    }

    & > button {
      @extend %ex_res-btn;

      text-align: left;
      background: transparent;
      padding: 0 0 10px 0;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;

      &:hover,
      &:visited {
        color: #5755d1;
      }

      &.tab__line {
        color: #5755d1;
      }

      span {
        position: absolute;
        z-index: 4;
        top: 50%;
        font-size: rem(10px);
        width: 1em;
        height: 1em;
        border-radius: 2em;
        margin-top: -0.5em;
        margin-left: rem(4px);
      }
    }
  }

  &__line {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 2px);
      height: 6px;
      background: radial-gradient(56.45% 50% at 50% 50%,#5755d1 7.81%,rgba(87,85,209,.354167) 60.42%,hsla(0,0%,100%,0) 100%);
    }
  }
}