@import "../../scss/_common/common";

.top {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}

.img_wrapper {
  & > div > div > div > div[class*="ImageLoad_drop"] {
    justify-content: center;

    & > div[class*="ImageLoad_drop__block"] {
      margin-bottom: 0;

      & > img {
        max-width: fit-content;
      }
    }
  }

  & > div > div > div > div > div > div > div[class*="MediaFile_imgBox"] {
    height: 52px;
    & > img {
      width: auto;
      height: auto;
    }
  }
}

.table_wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
