@import "src/scss/_common/common";


.wrap {
  font-size: rem(12px);
  overflow: auto;
  border: 1px solid #d8dfec;
  border-radius: 16px;
  //height: 65vh;
  background-color: #fff;

  @media screen and (max-width: 1600px) {}

  :global {
    .form-input {
      font-size: rem(12px);
      height: rem(30px);
    }
  }
}

table.table {
  border-collapse: inherit;

  :global {
    thead {
      background-color: #edeef0;

      & > tr > th {
        padding: 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #000;
        background-color: #edeef0;
        position: sticky;
        top: 0;
        white-space: nowrap;
        border-left: 0.05rem solid #d8dfec;
        border-right: 0 solid #d8dfec;

        &:last-child {
          right: -1px;
        }

        & > div {
          display: flex;
          align-items: center;
        }
      }

      & > tr:nth-child(1) > th {
        top: 0;
      }

      & > tr:nth-child(2) > th {
        top: 40px;
      }

      & > tr:first-child > th {
        border-bottom-width: 0 !important;
      }
    }

    tbody {
      background-color: #fff;
      & > tr {
        &:hover {
          background-color: #edeef0;
        }
        & > td {
          padding: 22px 12px;
          font-weight: 600;
          background-color: #fff;
          border-left: 0.05rem solid #d8dfec;
          border-right: 0 solid #d8dfec;

          &:last-child {
            position: sticky;
            right: -1px;
            z-index: 1;
          }

          & > span {
            position: relative;
            display: block;
            width: 100%;
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    tr > th,
    tr > td {
      padding: 12px;
      font-size: 12px;
      line-height: 16px;
      list-style: none;
      border-right: 0.05rem solid #d8dfec;
      &:first-child {
        padding-left: rem(12px);
        @media screen and (max-width: 1600px) {
          padding-left: rem(4px);
        }
      }
      @media screen and (max-width: 1600px) {
        padding: rem(4px);
        max-width: 200px;
        width: auto;
      }
      word-wrap: break-word;
    }

    tr {
      > td {
        img {
          position: relative;
          z-index: 1;
          transition: transform 0.2s;
          max-width: 100%;
          max-height: 48px;

          &:hover {
            transform: scale(2);
            z-index: 2;
          }
        }

        &:last-child {
          img {
            &:hover {
              transform: scale(1.5);
            }
          }
        }

        a > img,
        button > img {
          max-width: max-content;
        }
      }
    }

    .btn-group {
      flex-wrap: nowrap;
    }

    // @todo: move to controls component
    .table-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }
}