@import "../../../../scss/_common/common";

.block_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-right: 24px;
    width: 310px;
    & > div {
      margin-bottom: 0;
    }
  }

  &__button {
    display: flex;
    align-items: center;

    & > button,
    & > div {
      min-height: 51px;
      margin-right: 12px;
    }
  }
}

.table_wrapper {
  & > div {
    overflow: auto;
    max-height: 550px;
  }
}
