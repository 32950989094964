@import "src/scss/_common/common";

.table_item{

}

.table_item__search{

}

.table_item__table{
  padding: 25px;

}
.table_item__table__button{
  display: flex;
  justify-content: flex-end;
}

.table_item__table__container{
  width: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
}
