@import "src/scss/_common/common";

$gutter: 20px / 2;

.wrap {
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > .box {
    border-radius: 15px;
    overflow: hidden;

    & > div > div > div > input ~ div {
      width: 100%;
      height: 170px;
      background-color: transparent;
      margin-right: 0;
    }
  }
}

.box {
  padding: 0 rem($gutter) rem($gutter);
}

.ml_0:first-child {
  margin-left: 0;
}

.box_add {
  width: 128px;
  height: 170px;
}

.boxError {
  .drop {
    background: rgba(red, 0.35);
  }
}

.drop {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='black' stroke-width='1' stroke-dasharray='8%2c 12' stroke-dashoffset='7' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: rem(10px);
  border: 2px dashed #5755d1;
  background: #fff;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  > input {
    @extend %ex_input-hidden;
  }

  > label {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  > p {
    text-align: center;
  }

  > img {
    margin-bottom: 8px;
  }

  &__title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__text {
    color: #5755D1;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__desc {
    color: #828282;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    text-align: center;

    .drop__text {
      margin: 0 16px;
    }
  }
}

.inputDrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  opacity: 0;
}

.hover__btn_group {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  top: 50%;
  left: 50%;

  & > button:first-child {
    margin-bottom: 10px;
  }
}

button.btn_danger {
  border-color: #da3b2f;

  &:hover {
    border-color: #c2332a;
  }

  &:active,
  &:focus {
    border-color: #ff3225;
    background-color: #fbefee;
  }

  & > i {
    color: #da3b2f;
  }
}