@import "../../scss/_common/common";

.block__img {
  display: block;
  max-width: max-content;
  max-height: 270px;

  & > div > div {
    max-width: 480px;
    max-height: 270px;
    width: 480px !important;
    height: 270px !important;

    img {
      max-height: 270px;
    }
  }
}
