@import "src/scss/_common/common";

.wrap {
  display: flex;
  align-items: center;
  border: 1px solid #a2b3c2;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;

  input {
    padding: 2px 2px 2px 8px !important;
    max-height: 22px !important;
    border: 0 !important;
    border-radius: 0 !important;
    max-width: 115px !important;
  }
  input[type=date] {
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 50% !important;
  }

  div {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}