@import "../../scss/_common/common";

.top {
  display: flex;
  justify-content: flex-start;
  gap: 32px;

  &__item {
    display: flex;
    align-items: center;
  }

  &__block {
    display: flex;
    gap: 16px;
  }

  &__block__left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 442px;

    ul {
      list-style: none;
      overflow: auto;
      max-height: 80vh;
      width: 100%;
      margin: 0;

      li {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        color: #000;

        div {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__block__right {
    width: 80%;

    & > div[class*='TreeCredit_block'] {
      margin-left: 0;
    }
  }

  &__wrapper {
    max-width: 418px;
    min-width: 35%;
    margin-top: 0;
  }
}

.block {
  display: flex;
  justify-content: space-between;

  &__item {
    border: 1px solid #d8dfec;
    border-radius: 16px;
    overflow: hidden;
    width: 50%;
    background-color: #fff;

    &:first-child {
      margin-right: 32px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    padding: 20px 28px;
    background-color: #edeef0;
    border-bottom: 1px solid #d8dfec;
  }

  &__content {
    display: flex;
    margin: 28px;
    align-items: flex-start;

    @media screen and (max-width: 1550px) {
      flex-direction: column;
    }
  }

  &__show {
    background-color: #f2f0ef;
    border-radius: 20px;
    padding: 32px;
    color: #000;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 1550px) {
      min-width: 360px;
    }

    &_title {
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &_subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 8px;
      bottom: 8px;

      & > span {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        margin-right: 8px;
      }
    }
  }

  &__info {
    width: calc(100% - 392px);
    margin-right: 32px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}

.main {
  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    & > div {
      width: 50%;
      margin: 0 !important;
    }
  }
}

.content__wrapper{
  position: relative;
  .top__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 26px 0;
    width: 20%;
    position: absolute;
    top: -85px;

    div {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #5755D1 !important;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    & .active_link {
      display: inline-block;
      position: relative;


      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -9px;
        height: 2px; /* Толщина подчёркивания */
        background: linear-gradient(to right, rgba(87, 85, 209, 0), rgba(87, 85, 209, 1), rgba(87, 85, 209, 0));
      }
    }
  }
}

.formSelect {
  padding: 5px 16px 4px 10px;
  height: 52px;
  font-size: 15px;
  line-height: 20px;
  color: #808080;
  width: 100%;
  outline: none;
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 12px;
  transition: border .2s ease;
}