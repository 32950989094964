@import "../../scss/_common/common";

.disabled {
  cursor: none;
  pointer-events: none;
  label {
    color: #828282;

    &:after,
    &:before {
      opacity: .5;
    }
  }
}

.top {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1441px) {
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-right: 32px;
    min-width: 360px;

    @media screen and (max-width: 1441px) {
      margin-right: 0;
      margin-top: 32px;
    }

    @media screen and (max-width: 700px) {
      min-width: auto;
    }
  }

  &__img_block {
    margin-right: 32px;
    width: 100%;

    @media screen and (max-width: 1441px) {
      margin-right: 0;
      margin-top: 32px;
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 900px) {
      margin-right: 0;
    }
  }
}

.block {
  display: flex;
  justify-content: space-between;

  &__item {
    border: 1px solid #d8dfec;
    border-radius: 16px;
    overflow: hidden;
    width: 50%;
    background-color: #fff;

    &:first-child {
      margin-right: 32px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    padding: 20px 28px;
    background-color: #edeef0;
    border-bottom: 1px solid #d8dfec;
  }

  &__content {
    display: flex;
    margin: 28px;
    align-items: flex-start;

    @media screen and (max-width: 1550px) {
      flex-direction: column;
    }
  }

  &__show {
    background-color: #f2f0ef;
    border-radius: 20px;
    padding: 32px;
    color: #000;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 1550px) {
      min-width: 360px;
    }

    &_title {
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &_subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 8px;
      bottom: 8px;

      & > span {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        margin-right: 8px;
      }
    }
  }

  &__info {
    width: calc(100% - 392px);
    margin-right: 32px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}

.img_block {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}


.media {
  height: 100%;

  * {
    margin-bottom: 0 !important;
  }

  & > div > div {
    height: 320px !important;
  }
}
