@import "src/scss/_common/common";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;

  table {
    & > thead > tr > th,
    & > tbody > tr > td {
      &:nth-child(even),
      &:nth-child(odd) {
        width: auto;
      }
    }
  }

  &__content {
    padding: 32px;
    margin: 32px;
    border-radius: 20px;
    background-color: #fff;
    max-width: 1310px;
  }

  &__header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  &__box {
    overflow: auto;
    max-height: 65vh;
    padding-right: 10px;
    & > div {
      filter: none !important;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    & > div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin-right: 23px;
  }

  &__update {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5755d1;
    cursor: pointer;
    margin-right: 15px;

    & > img {
      margin-right: 8px;
    }
  }

  &__unlock {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #34a853;
    cursor: pointer;

    & > img {
      margin-right: 8px;
    }
  }

  &__lock {
    color: #da3b2f;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    & > img {
      margin-right: 8px;
    }
  }
}

.active {
  opacity: 1;
  z-index: 99999;
  pointer-events: all;
}

.not_found {
  font-size: 16px;
  max-width: 450px;
}

.wrapper__input {
  margin-top: 20px;
}

.suptitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.special_offer {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__wrapper {
    border: 1px solid #d8dfec;
    border-radius: 16px;
  }

  &__block {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #d8dfec;
    }

    & > div {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 20px 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000;

      &:not(:last-child) {
        border-right: 1px solid #d8dfec;
      }

      &:nth-child(1) {
        min-width: 40px;
        margin-right: 0;

        & > label {
          &:before,
          &:after {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &:nth-child(2) {
        min-width: 90px;
      }
      &:nth-child(3) {
        min-width: 545px;
      }
      &:nth-child(5) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        & > span {
          color: #828282;
          text-decoration-line: line-through;
        }
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin-bottom: 10px;
  }

  &__cat {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    &_item {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      border: 1px solid #D8DFEC;
      background: #FFF;
      padding: 6px 12px 6px 12px;
      border-radius: 30px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: #EDEEF0;
      }

      &_active {
        font-weight: 600;
        background: #EDEEF0;
      }
    }
  }
}

.width_auto {
  thead {
    tr {
      th {
        min-width: auto !important;
      }
    }
  }
  tbody {
    tr {
      td {
        min-width: auto !important;
      }
    }
  }
}

.error_message {
  color: #da3b2f;
  font-size: 15px;
}

.offer_custom {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin-bottom: 10px;

    & > div {
      margin-right: 10px;
    }
  }
}