.order_items_container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    &:first-child {
      max-width: 320px;
    }
    width: 340px;
  }
}
