@import "../../../../scss/_common/common";

.wrap {
  text-align: right;

  :global {
    .input-group {
      display: inline-flex;
    }

    .form-selectss {
      $sz: 40px;

      width: auto;
      min-width: rem($sz);
    }
  }
}