@import "../../scss/_common/common";

.wrapper {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;

  &__top {
    width: 100%;
  }

}

.left {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 360px;

  @media screen and (max-width: 800px) {
    min-width: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
  }
}

.table_wrapper {
  & > div {
    overflow: auto;
    max-height: 550px;
  }
}

.title_cat {
  background: #C8D8F6;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  border-radius: 6px;
  padding: 4px 6px;
  margin-right: 16px;
  min-width: 179px;
}

.btn_cat {
  padding: 4px 6px;
  border-radius: 6px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  min-width: 30px;

  &__green {
    background-color: #C9EAB9;
  }
  &__red {
    background-color: #FCD0D0;
  }
}

button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}


.table_inner {
  width: calc(100% - 395px);

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}