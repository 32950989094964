@import "../../scss/_common/common";

.top {
  display: flex;
  justify-content: flex-start;
  gap: 32px;

  @media screen and (max-width: 1201px) {
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 90px;
  }
}

.block {
  width: 100%;
  max-width: 683px;
}


.media {
  height: 100%;
  * {
    margin-bottom: 0 !important;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & > div > div {
    height: 60px !important;
  }
}
