@import "src/scss/_common/common";

.wrap {

  & > div > ol {
    margin-left: 0;

    & > li {
      margin-top: 0;

      & > span > label > span {
        color: #000 !important;
      }
      & > ol {
        margin-top: 20px;
        position: relative;
        &:after {
          content: "";
          background-color: #d8dfec;
          position: absolute;
          top: -13px;
          width: 500px;
          max-width: 98%;
          height: 1px;
        }
      }
    }
  }

  ol {
    list-style: none;
    margin: 0 0 0 30px;
  }

  button[type="button"],
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
  }

  span[role="checkbox"] {
    display: contents;
  }

  span[class="rct-title"] {
    word-break: break-word;
  }

  span[aria-checked="true"] ~ span[class="rct-title"] {
    color: #34a853;
  }

  span[aria-disabled="true"] > img,
  span[aria-disabled="true"] ~ span[class="rct-title"] {
    opacity: .5;
  }
}

.wrap_all {
  & > div > ol > li > ol {
    margin-left: 0;
  }
}

.expandedBtn {
  button[type="button"] {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  span[class="rct-text"] {
    display: flex;
    align-items: center;
  }

  ol {
    margin: 0 0 0 45px;
  }
}

.img {
  padding-right: 6px;
  transition: all 0.3s;

  &__left {
    margin-right: 5px;
  }
  &__right {
    transform: rotate(90deg);
    margin-right: 5px;
  }
}