@import "../../../../scss/_common/common";


.condition {
  &__radio {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-column-gap: 32px;
    label {
      white-space: nowrap;
    }

    & > div {
      width: max-content;
    }
  }

  &__block {

    ul {
      list-style: none;
      overflow: auto;
      max-height: 275px;
      width: 100%;

      li {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        color: #000;

        div {
          margin-bottom: 8px;
        }
      }
    }
  }
}


.type {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8dfec;

  &__label {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-right: 24px;
  }
}

button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
}
