/* # State
   ========================================================================== */
.-w-100 {
  width: 100%;
}
.w-100 {
  width: 100%!important;
}

.-d-none {
  display: none !important;
}

.-hidden-vis {
  visibility: hidden;
  opacity: 0;
}

.-sticky {
  position: sticky;
  top: rem(20px);
}

.b-none {
  border: none;
}

.vertical-center {
  vertical-align: middle;
  align-items: center;
}

.truncate-1 {
  margin: 0; /* Убираем внешний отступ */
  -webkit-line-clamp: 1; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}

.truncate-2 {
  margin: 0; /* Убираем внешний отступ */
  -webkit-line-clamp: 2; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}

.custom_color_link {
  color: #5755d9;
  outline: none;
  text-decoration: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: #302ecd;
    text-decoration: underline;
  }
}
