@import "src/scss/_common/common";

.select {
  & div{
    color: black;
  }
  & > div[class*='-control'] {
    border-radius: 12px;
    border: 1px solid #A2B3C2;
    background: #FFF;
    padding: 8px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    & > div:last-child {
      align-items: flex-start;
      margin-left: 25px;

      span[class*='-indicatorSeparator'] {
        display: none;
      }

      div[class*='-indicatorContainer'] {
        border: 2px solid #DA3B2F;
        border-radius: 50%;
        padding: 0;
        margin-left: 8px;
        cursor: pointer;
        width: 18px;
        height: 18px;

        &:last-child {
          border-radius: 0;
          border: 1px solid transparent;

          & > svg {
            color: #CCCCCC;
          }
        }

        & > svg {
          color: #DA3B2F;
          width: 20px;
          height: 14px;
        }
      }
    }



    div[class*='-ValueContainer'] {
      padding: 0;
      margin: 0;

      div[class*='-placeholder'] {
        color: #808080;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0;
        margin: 0;
      }

      div[class*='-Input'] {
        padding: 0;
        margin: 0;
      }
    }

    div[class*='-IndicatorsContainer'] {
      align-items: flex-start;
      margin-left: 25px;

      span[class*='-indicatorSeparator'] {
        display: none;
      }

      div[class*='-indicatorContainer'] {
        border: 2px solid #DA3B2F;
        border-radius: 50%;
        padding: 0;
        margin-left: 8px;
        cursor: pointer;
        width: 18px;
        height: 18px;

        &:last-child {
          border-radius: 0;
          border: 1px solid transparent;

          & > svg {
            color: #CCCCCC;
          }
        }

        & > svg {
          color: #DA3B2F;
          width: 20px;
          height: 14px;
        }
      }
    }
  }
}

:global {
  .input-group {
    :local(.select) {
      flex: 1 0 auto;
    }
  }
}

.selectForm {
    padding: 18px 4px;
    height: 52px;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    width: 100%;
    outline: none;
    background: #fff;
    border: 1px solid #d8dfec;
    border-radius: 12px;
    transition: border 0.2s ease;
}

