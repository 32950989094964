@import "src/scss/_common/common";

.wrap {
  vertical-align: bottom;
  :global {
    th, td {
      position: relative;
      z-index: 1;
    }
  }
}

.wrapLoading {
  :global {
    th, td {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $color-white;
        opacity: 0.8;
        z-index: 4;
      }
    }
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
  margin-top: 5px;

  & > div {
    margin-bottom: auto;
  }
}

.baseline {
  vertical-align: baseline;
  padding: 0 10px 10px !important;

  &:first-child {
    padding-left: 20px !important;
    @media screen and (max-width: 1600px) {
      padding-left: 4px !important;
    }
  }
}

.vertical_align {
  vertical-align: middle;
  padding-left: 20px !important;
  @media screen and (max-width: 1600px) {
    padding-left: 10px !important;
  }
};

.input {
  &_text {
    border: 1px solid #a2b3c2;
    border-radius: 6px;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    &::placeholder {
      color: #828282;
    }
  }

  &_checkbox {
    display: flex;
    align-items: center;

    div {
      margin-bottom: 0 !important;
    }

    input {
      width: 18px;
      height: 18px;
      z-index: 9;
      cursor: pointer;
    }

    label {
      &:after,
      &:before {
        left: 0 !important;
        transform: translate(0, -50%) !important;
      }
    }
  }

  &_select {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #a2b3c2;
    border-radius: 6px;
    padding: 2px 8px;
    width: 100%;

    &::placeholder {
      color: #828282;
    }
  }
}

.wrap_date_input {
  border: 1px solid #a2b3c2;
  border-radius: 6px;
  overflow: hidden;

  input {
    padding: 2px 2px 2px 8px !important;
    max-height: 22px !important;
    border: 0 !important;
    border-radius: 0 !important;
    max-width: 115px !important;
  }
  input[type=date] {
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 50% !important;
  }

  div {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}

.calcOrderWrapper {
  background: #5755D1;
  color: #fff;
  width: 100%;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  border-color: transparent;
  cursor: pointer;
}