@import "../../../../scss/_common/common";


.trLoad {
  opacity: 0.5;
  pointer-events: none;
}

.trDisabled {
  pointer-events: none;

  &:first-child {
    td {
      padding-top: rem(100px);
    }
  }

  :global {
    td {
      position: relative;
      z-index: 1;
      border: 0;
      padding: 0;
    }

    .table-controls {
      position: relative;
      z-index: 1;
      visibility: hidden;
      pointer-events: none;
      height: 0;
    }
  }
}

tr.table_row {
  select {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #a2b3c2;
    border-radius: 6px;
    padding: 2px 8px;
    width: 100%;
    max-height: 30px;

    &::placeholder {
      color: #828282;
    }
  }

  &:nth-child(even) {
    & > td {
      background-color: #edeef0;
    }
  }
}

button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}