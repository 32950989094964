@import "src/scss/_common/common";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;

  &__content {
    padding: 32px;
    margin: 32px;
    border-radius: 20px;
    background-color: #fff;
    min-width: 450px;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 8px;
  }
}

.active {
  opacity: 1;
  z-index: 99999;
  pointer-events: all;
}

.radio__item {
  display: flex;
  align-items: center;
  &:not(last-child) {
    margin-bottom: 10px;
  }

  div {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  label {
    white-space: nowrap;
    &:after,
    &:before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.mt_15 {
  margin-top: 25px;
  margin-bottom: 0;
}