@import "../../../../scss/_common/common";

.wrap {
  text-align: right;
  display: flex;
  align-items: center;

  :global {
    .input-group {
      display: inline-flex;
    }

    .form-selectss {
      $sz: 40px;

      width: auto;
      min-width: rem($sz);
    }
  }
}

.block {
  display: flex;
  align-items: center;
  margin-right: 16px;

  & > span {
    margin-right: 8px;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.select {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  border: 2px solid #5755d1;
  border-radius: 4px;

  &__trigger {
    display: flex;
    align-items: center;
    padding: 1px 4px;

    & > span {
      margin-right: 7px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #5755d1;
    }

    & > img {
      transition: all 0.3s;
    }
  }

  &__open {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #fff;
    border: 2px solid #5755d1;
    border-radius: 4px;
    z-index: 5;
  }

  &__item {
    font-size: 16px;
    line-height: 22px;
    padding: 2px 10px;
    color: #5755d1;

    &:hover {
      background-color: #edeef0;
    }
  }
}

.active {
  font-weight: 600;
}

.open {
  transform: rotateX(160deg);
}