.typeField__item {
  display: flex;
  width: 100%;
  min-height: 200px;
  //background: #cccccc;
  margin: 4px;
  box-sizing: border-box;
}

.typeField__item .DraftEditor-root{
  width: 100%;
  height: 100%;
}

.typeField__item .tox-notifications-container {
  display: none !important;
}

.container_with_errors {
  border: 1px solid red;
}
