@import "src/scss/_common/common";

.wrap {
  margin-left: 12px;
  position: relative;

  &__img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all .2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;

    &:hover {
      transform: translateY(-50%) rotate(90deg);
    }

    & > img {
      width: 7px;
      height: 7px;
    }
  }
}

.input {
  background: #fff;
  border: 1px solid #a2b3c2;
  border-radius: 6px;
  outline: none;
  padding: 1px 14px 1px 6px;
  font-size: 12px;
  line-height: 16px;
  color: #828282;

  &:hover {
    border-color: #a2b3c2;
  }

  &:focus {
    border-color: #000;
  }

  &:active {}
}