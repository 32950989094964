@import "../../../scss/_common/common";

.alertText {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 3;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: black;
  color: #fff;

}

.activeAlert:hover span {
  visibility: visible;
  opacity: 1;
}