/* # Layout
   ========================================================================== */
// page layout
$left__width: 70px;

.page-wrap {
  position: relative;
  z-index: 1;
  height: 100%;
}

.page-aside {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: rem($left__width);
  z-index: 9999;

  & + .page-container {
    width: calc(100% - #{rem($left__width)});
    margin: 0 0 0 auto;

    @media screen and (max-width: 1600px) {
      width: calc(100% - #{rem($left__width / 2)});
    }
  }

  @media screen and (max-width: 1600px) {
    width: rem($left__width / 2);
  }
}


//footer down
html,
body,
#root {
  height: 100%;
}

.page-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page-top,
.page-bottom {
  flex-shrink: 0;
}

.page-sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.page-content {
  flex-grow: 1;
}

// page styles
.page-content {
  margin: rem(20px) 0;

  @media screen and (max-width: 1600px) {
    margin: rem(10px) 0;
  }

  > .container {
    padding: 0 rem(40px);
    @media screen and (max-width: 1600px) {
      padding: 0 rem(10px);
    }

    & > div:not(.box-wrap) {
      margin: 20px 0 30px 0;
    }
  }
}

// form-input styles
.form-input:not(:placeholder-shown):invalid {
  border-color: #bcc3ce;
}


// flex style
.d_flex {
  display: flex;
  align-items: center;
  & > div {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}