@import "src/scss/_common/common";

.wrap {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.box {
  padding: 32px;
  background-color: #fff;
  position: relative;
  min-height: 100px;
  min-width: 100px;
  border-radius: 20px;
}

.btn_close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  opacity: 0.4;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
}