@import "src/scss/_common/common";


.form {
	:global {
		.form-group + .form-group {
			margin-top: rem(30px);
		}

		.form-group {
			//align-items: center;
			max-width: rem(660px);
		}

		.divider,
		.divider[data-content] {
			margin: rem(60px) 0;
		}

		.form-input-hint {
			margin: 0;
			color: #da3b2f;
		}

		.input-group {
			.input-group-btn {
				height: auto;
			}
		}
	}
}