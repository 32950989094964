@import "../../scss/_common/common";

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &__item {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-right: 32px;
    min-width: 260px;

    @media screen and (max-width: 1300px) {
      min-width: auto;
    }
  }

  &__file {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    min-width: auto;

    &_text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.content {
  display: flex;
}

.block {
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;

  &:not(:last-child) {
    margin-right: 32px;
  }

  &__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    background: #edeef0;
    padding: 20px 28px;
    border-bottom: 1px solid #d8dfec;
  }

  &__content {
    display: flex;
    padding: 28px;
    width: 100%;

    @media screen and (max-width: 1600px) {
      flex-direction: column;
    }
  }

  &__fields {
    margin-right: 32px;
    width: 100%;

    & > div {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}

.show {
  background-color: #f2f0ef;
  border-radius: 20px;
  padding: 24px 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  min-width: 360px;

  &__img {
    width: 48px;
    height: 48px;
    margin: 0 auto 12px;
  }

  &__title {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    font-weight: 700;
    margin: 0 0 8px;
  }

  &__desc {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    max-width: 230px;
  }
}