@import "src/scss/_common/common";

.select__inner {
  margin-bottom: 10px;
  position: relative;

  & > p {
    font-size: 12px;
    line-height: 14px;
    color: #da3b2f;
  }

  & > div {
    width: 100%;
  }

  &_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > button {
      margin-left: 12px;
    }
  }
}

.select__wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  line-height: 3;
  border-radius: .25em;
  flex-direction: column;
  width: 100%;

  & ~ button {
    margin-left: 15px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 3px;
    background-color: #fff;
    background-image: url("../../../assets/icons/icon_arrow-down-black.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 20px 16px;
    cursor: pointer;
    pointer-events: none;
    transition: .25s all ease;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.select__multi {
  & > div[class*='RSelect_select'] > div[class*='-control'] {
    padding: 16px;
  }
}

.label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 15px;
  line-height: 20px;
  color: #808080;
  transition: transform .2s ease;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  pointer-events: none;

  &_react_select {
    transform: translateY(0) scale(.8);
    top: 5px;
  }
}

select.select {
  -webkit-appearance:none;
  -moz-appearance:none;
  -ms-appearance:none;
  appearance:none;
  padding: 18px 16px 4px 16px;
  height: 52px;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 12px;
  transition: border .2s ease;
  cursor: pointer;

  &:focus + .label,
  &:not(:placeholder-shown) + .label,
  & ~ input + .label {
    transform: translateY(-100%) scale(.8);
  }

  &:hover {
    border-color: #a2b3c2;
  }

  &:focus {
    border-color: #000;
  }

  &::-ms-expand {
    display: none;
  }
}

textarea.select_error,
select.select_error {
  color: #da3b2f !important;
  border-color: #ffa9a3 !important;

  &:focus {
    border-color: #ffa9a3 !important;
  }

  option {
    color: black !important;
  }

  & ~ label {
    color: #da3b2f !important;
  }
}

.textarea__wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  display: flex;
  line-height: 3;
  border-radius: .25em;
  width: 100%;
}

textarea.textarea {
  padding: 18px 16px 4px 16px;
  min-height: 132px;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  border: 1px solid #d8dfec;
  border-radius: 12px;
  transition: border .2s ease;

  &::placeholder {
    color: transparent
  }

  &:focus + .label__textarea,
  &:not(:placeholder-shown) + .label__textarea {
    transform: translateY(-100%) scale(.8);
    top: 25px;
  }

  &:hover {
    border-color: #a2b3c2;
  }

  &:focus {
    border-color: #000;
  }
}

.label__textarea {
  position: absolute;
  top: 19px;
  left: 16px;
  font-size: 15px;
  line-height: 20px;
  color: #808080;
  transition: transform .2s ease;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  pointer-events: none;
}

.text__wrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  div {
    &:first-child {
      white-space: nowrap;
    }
  }
}

.text {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  text-align: right;
}

.prevOrder {
  margin: 0;
  list-style: none;
}

.prevOrder__item {
  margin: 0;
  padding: 16px 20px;
  background-color: #f9f9fa;

  &:nth-child(odd) {
    background-color: #fff;
  }
}

.prevOrder__block {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 50%;
  }
}

.prevOrder__box {
  margin-bottom: 10px;

  & > a {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #5755d1;
  }

  & > div {
    font-size: 11px;
    line-height: 15px;
  }
}

.prevOrder__status {
  margin-bottom: 10px;
  text-align: right;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #22a4cd;
}

.prevOrder__box_price {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > div {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    &:first-child {
      margin-right: 20px;
    }
  }
}

.prevOrder__box_info {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
}

.comment__item {
  margin: 0;
  padding: 16px 20px;
  background-color: #f9f9fa;

  &:nth-child(odd) {
    background-color: #fff;
  }
}

.comment__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.comment__author {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
}
.comment__date {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
}
.comment__text {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
}

.promocode {
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }

  & > button {
    cursor: pointer;
    height: 36px;
    width: 36px;
    border: 2px solid #1428a0;
    margin-bottom: 15px;
    background-color: transparent;
    background-image: url("../../../assets/icons/icon_check_promo.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 16px;
    transition: all 0.3s;

    &:hover {
      border-color: #405efd;
    }

    &:disabled {
      cursor: auto;
      border-color: #626263;
    }
  }

  & > span {
    cursor: pointer;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 18px;
    transition: color 0.3s;
    &:hover {
      color: #1428a0;
    }
  }

  &__btn {
    cursor: pointer;
    height: 52px;
    width: 48px;
    //background-color: transparent;
    background-image: url("../../../assets/icons/icon_check_promo.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: all 0.3s;
    background-color: #fff;
    border-color: #5755D1;
    border-width: 1px;
    border-radius: 0 12px 12px 0;

    &:hover {
      border-color: #405efd;
    }

    &:disabled {
      cursor: auto;
      border-color: #626263;
    }
  }
}

.col_full {
  display: flex;
}

.no_user {
  background-color: #c2332a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.phone {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__address {
    white-space: normal;
  }
}

.additionalTabs {
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  color: #5755D1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .tabs__wrapper {
    display: flex;
    justify-content: space-between;
    min-width: 230px;
  }

  .default_tab {
    min-width: 70px;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }

  & .active_tab {
    display: flex;
    justify-content: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 30px;
      height: 2px; /* Толщина подчёркивания */
      background: linear-gradient(to right, rgba(87, 85, 209, 0), rgba(87, 85, 209, 1), rgba(87, 85, 209, 0));
    }
  }
}