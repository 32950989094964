@import "../../../scss/_common/common";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 32px;
}

.block {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: #FFF;
  border: 2px solid #A2B3C2;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 40px;
  cursor: pointer;
}