@import "../../../../scss/_common/common";

.block {
  border-radius: 16px;
  border: 1px solid #d8dfec;
  overflow: hidden;

  @media screen and (max-width: 1701px) {
    margin-top: 16px;
  }

  &__title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    padding: 20px 28px;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid #d8dfec;
    background-color: #edeef0;
  }

  &__content {
    padding: 28px;
    background-color: #fff;
  }
}