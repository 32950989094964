@import "src/scss/_common/common";

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d8dfec;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  background-color: #d8dfec;

  input {
    border: 1px solid #a2b3c2;
    border-radius: 6px;
    height: auto;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    width: 100%;
    padding: 2px 2px 2px 8px;
    background-color: #fff;

    &:focus {
      border: 1px solid #a2b3c2;
    }

    &:read-only,
    &:disabled {
      background: #edeef0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      margin: 0;
    }

    @media screen and (max-width: 1370px){
      padding-left: 4px;
      min-width: 20px;
    }
  }

  input[type="checkbox"] {
    border: none;

    &:disabled {
      background-color: #fff;
      border: 1px solid #deddf5;
      border-radius: 4px;
      width: 19px;
      height: 19px;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      z-index: 2;
    }

    &:read-only {
      background-color: #fff;
    }
  }

  &__wrapper {
    overflow: auto;
  }

  &__inner {
    filter: drop-shadow(0 4px 32px rgba(0, 0, 0, 0.09));
  }

  &__disable {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0, 0.25);
      cursor: no-drop;
      border-radius: 15px;
      z-index: 10;
    }
  }

  & > thead > tr > th,
  & > tbody > tr > td {
    &:nth-child(1) {
      min-width: 40px;
    }
    &:nth-child(2) {
      min-width: 100px;
    }
    &:nth-child(3) {
      min-width: 520px;
    }
    &:nth-child(4) {
      min-width: 105px;
    }
    &:nth-child(5) {
      min-width: 155px;
    }
    &:nth-child(6) {
      min-width: 75px;
    }
    &:nth-child(7) {
      min-width: 75px;
    }
    &:nth-child(8) {
      min-width: 105px;
    }
    &:nth-child(9) {
      min-width: 88px;
    }
    &:nth-child(10) {
      min-width: 98px;
    }
    &:nth-child(11) {
      min-width: 87px;
    }
    &:nth-child(12) {
      min-width: 93px;
    }
    &:nth-child(13) {
      min-width: 130px;
    }
    &:nth-child(14) {
      min-width: 40px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(8),
    &:nth-child(13) {
      @media screen and (max-width: 1880px) {
        min-width: auto;
      }
    }
  }
}

.thead {
  background-color: #edeef0;

  & > tr {
    & > th {
      padding: 0;
      border-right: 1px solid #d8dfec;

      &:last-child {
        border-right: 0;
      }

      & > div {
        white-space: nowrap;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000;
        padding: 6px 4px;
      }
    }
  }
}

.tbody {
  vertical-align: top;

  & > tr {
    background-color: #fff;

    &:nth-child(odd) {
      background-color: #f9f9fa;
    }

    & > td {
      border: 1px solid #d8dfec;

      & > div {
        padding: 4px;
      }

      a {
        font-size: 14px;
        line-height: 19px;
        color: #5755d1;
      }

    }
  }
}

.service__block {
  background-color: #374561;
  color: #fff;
  max-width: 32px;
  height: 10px;
  border-radius: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  width: 30px;
  margin-right: 15px;

  &_second {
    background-color: #376157;
  }
  &_third {
    background-color: #613758;
  }
}

.color {
  &_service {
    &_1 {
      background-color: #f3f4f6 !important;
    }
    &_2 {
      background-color: #eff2f2 !important;
    }

    &_3 {
      background-color: #f2eff2 !important;
    }
  }

  &_offer {
    &_1 {
      background-color: #f2fafc !important;
    }
    &_2 {
      background-color: #fcfae7 !important;
    }

    &_3 {
      background-color: #fdf5e6 !important;
    }

    &_4 {
      background-color: #eff8f1 !important;
    }

    &_5 {
      background-color: #fdf4fc !important;
    }
  }
}

.offer__block {
  background-color: #22a4cd;

  &_2 {
    background-color: #e2cd0b;
  }

  &_3 {
    background-color: #e89a03;
  }

  &_4 {
    background-color: #34a853;
  }
  &_5 {
    background-color: #d649c8;
  }
}

.jde {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.id {
  margin-top: 120px;
}

.product__block {
  margin-bottom: 12px;
}

.product_service__title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  margin-bottom: 8px;
}

.product_service__block {
  position: relative;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

}

input.product_service__input {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 18px;
  height: 18px;

  &:checked + .product_service__label:before {
    opacity: 1;
    z-index: 1;
  }
}

.product_service__label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding-left: 26px;

  &:after,
  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    left: 0;
    top: 50%;
    border: 1px solid #5755d1;
    background-color: #fff;
    transform: translateY(-50%);
    transition: opacity .3s;
  }

  &:before {
    border-width: 5px;
    opacity: 0;
  }
}

.purchase__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.purchase__price {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  margin-right: 8px;
}

.availability__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.availability__stock {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-right: 16px;
  white-space: nowrap;
}

.availability__counter {
  background: #edeef0;
  border: 1px solid #a2b3c2;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  height: 20px;
  min-width: 23px;
  padding: 1px;
}

.price_all {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  white-space: nowrap;
}

.price_all_discount {
  color: #828282;
}

.manager__name {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.btn__wrapper {
  margin: 12px 0;
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 16px;
  }

  button {
    white-space: nowrap;
  }
}

.btn__delete {
  width: 20px;
  height: 20px;
  border: 2px solid #da3b2f;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #da3b2f;
    border-radius: 10px;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:disabled {
    opacity: 0.6;
    cursor: no-drop;
  }
}

.label {
  &:before,
  &:after {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #edeef0;
  border: 1px solid #d8dfec;
  border-top: none;
  border-radius: 0 0 16px 16px;
  margin-bottom: 32px;

  & > div {
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 70px;
    }
  }

  input {
    background: #fff;
    border: 1px solid #a2b3c2;
    border-radius: 6px;
    max-width: 170px;
    height: 32px;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    width: 100%;
    padding: 5px 8px;

    &:focus {
      border: 1px solid #a2b3c2;
    }

    &:disabled {
      background: #edeef0;
    }
  }
}

.suborder {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  button {
    width: 120px;
  }
}

.add_product {
  display: flex;
  align-items: center;
  padding: 0 22px;

  & > div {
    margin-right: 8px;
  }

  input {
    width: 318px;
    max-width: 318px;
  }
}

.bonus_user {
  display: flex;
  align-items: center;
  margin-right: 20px;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 8px;
    position: relative;

    & > label {
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      padding: 0 6px;
      z-index: 1;

      &:read-only {
        opacity: 0.6;
      }
    }

    & > input {
      text-align: right;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      width: 170px;

      &:read-only {
        opacity: 0.6;
        cursor: no-drop;
      }
    }
  }
}

.total_price {
  display: flex;
  align-items: center;

  & > div {
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    & > b {
      padding-left: 8px;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    & > span {
      color: #828282;
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}

.table_settings {
  display: flex;
  align-items: center;
  //margin-top: 16px;

  button {
    height: 48px;
  }
}

.table_settings__block {
  margin-right: 24px;
  min-width: 180px;
}

.icon__block {
  display: flex;
  align-items: center;

  div + div {
    margin-left: 18px;
  }
}

.disable_return_quantity {
  position: relative;
  z-index: 11;
}

.table__calls {
  border-collapse: collapse;
  width: 100%;

  tr > td, tr > th {
    border-left: 0.05rem solid #d8dfec;
    border-right: 0 solid #d8dfec;
    text-align: center;
  }

  tr > td:nth-last-child(1) {
    padding: 0 !important;
  }

  tr > th {
    border-bottom: 1px solid #d8dfec
  }
}

tr.background_red {
  background: #ffebee !important;
}