@import "../../scss/_common/common";

.wrap {
  display: flex;
}

.left {
  margin-right: 32px;
  width: 500px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  &__item {
    div {
      margin-right: 0 !important;
    }
  }
}

.show {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 360px;
  padding: 32px;
  max-width: 490px;

  &__top {
    position: relative;
    margin-bottom: 24px;
  }

  &__img {
    width: auto;
    height: 317px;
    margin: 0 auto;
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  &__price {
    font-weight: 700;
    font-size: 19px;
    line-height: 25px;

    &_small {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__mounth {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    & > span {
      margin-right: 4px;
    }
  }

  &__credit {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & > div {
      display: flex;
      align-items: center;
      margin-right: 12px;

      & > img {
        margin-right: 4px;
      }
    }
  }

  &__btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 11px 34px;
    color: #fff;
    background: #1428a0;
    border-radius: 22px;
    width: max-content;
  }

  &__jde {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #555555;
    margin-bottom: 16px;

    &_green {
      color: #4a841f;

      & > span {
        margin-left: 4px;
        margin-right: 12px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;

    & > div:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.markers {
  position: absolute;
  top: -12px;
  left: -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  margin: 0;
  list-style: none;
  padding: 0;

  &__item {

  }
}

.colors {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  top: -12px;
  right: -12px;
  row-gap: 12px;
  margin: 0;
  list-style: none;
  padding: 0;

  & > li {
    margin: 0;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 50%;
  }

  &__item {
    width: 24px;
    height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    margin: 0;

    &_pink {
      background-color: #ffb9b9;
    }

    &_black {
      background-color: #262729;
    }

    &_white {
      background-color: #fff;
    }
  }

  &__active {
    border-color: #0077c8 !important;
  }
}