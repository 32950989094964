@import "src/scss/_common/common";

.checkbox__wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  cursor: pointer;

  &:checked {
    & + .label:before {
      background-color: #5755d1;
    }
    & + .label:after {
      opacity: 1;
    }
  }

  &:disabled {
    cursor: no-drop;
    background-color: #fff;
    border: 1px solid #deddf5;
    border-radius: 4px;
    width: 19px;
    height: 19px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    & + .label {
      cursor: no-drop;
    }
  }
}

.label {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-left: 25px;
  cursor: pointer;
  min-width: 18px;
  min-height: 18px;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .2s;
  }

  &:before {
    border: 1px solid #5755d1;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
  }

  &:after {
    opacity: 0;
    background-image: url("../../../../assets/icons/icon_checkbox.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    color: #fff;
    z-index: 2;
  }

  &_disable {
    &:before,
    &:after {
      opacity: 0.5;
    }
  }

  &_indeterminate {
    &:after {
      background-image: url("../../../../assets/icons/icon_indeterminate.svg");
      opacity: 1;
    }
  }

  &_left {
    padding-right: 30px;
    position: relative;
    &:before,
    &:after {
      left: 0;
      transform: translate(0, -50%);
    }
  }

  &_right {
    padding-right: 30px;
    position: relative;
    &:before,
    &:after {
      left: auto;
      right: 6px;
      transform: translate(0, -50%);
    }
  }
}

.display_f {
  display: flex;
  align-items: center;
  gap: 3px;
}

.firstColumn{
  padding-left: 0.4rem !important;
}