@import "./src/scss/_common/common";

.inputFetchResult__container{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 999;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  max-height: 250px;
  overflow: auto;
}

.inputFetchResult__option{
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.inputFetchResult__wrap{
  position: relative;

  & > div {
    margin: 0;
  }
}

.sm {
  top: 35px;
}
