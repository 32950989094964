.order_main_container{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  th{
    padding: 0.3rem 0.4rem!important;
  }
  td{
    padding: 0.4rem 0.4rem!important;
  }
  label.form-label{
    line-height: 22px;
  }
}
