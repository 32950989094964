@import "src/scss/_common/common";

.wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgba($color-white, 0.8);
}

.loader {
  position: relative;
  //top: rem(100px);
  top: 45%;
}