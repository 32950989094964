@import "../../scss/_common/common";

.inner {
  display: flex;
  justify-content: space-between;
  form {
    width: 100%;
  }

  label {
    max-width: 100%;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  &__block {
    display: flex;
    align-items: center;
    & > div {
      &:first-child {
        margin-right: 32px;
        width: 866px;
      }
      &:last-child {
        width: 433px;
      }
    }
  }

  &__checked {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;
    margin-bottom: 20px;
  }

}

.block {
  display: flex;
  align-items: flex-start;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-bottom: 20px;
}

div.mb20 {
  margin-bottom: 20px !important;

  input {
    padding-right: 40px;
  }
}

div.search_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  > div[class*='InputWrapper_wrapper'] {
    width: 100%;
    margin: 0;
  }
}
