@import "src/scss/_common/common";

$item_mb: 20px;
$item_pX: 20px;

.wrap {
  max-width: rem(1400px);
  margin: 0 auto;
}

.reverse {
  flex-direction: row-reverse;
}