@import "src/scss/_common/common";

.wrap {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  min-height: 40px;
  flex-wrap: wrap;
  position: relative;

  & > div {
    &:first-child {
      margin-right: 12px;
    }
  }

  img {
    cursor: pointer;
    display: block;
  }
}

.img {
  position: relative;
  display: flex;
  align-items: center;

  & > img {
    max-width: 100%;
    width: 25px !important;
    height: 25px !important;
    object-fit: none !important;
    transform: scale(1) !important;
  }
}

.copy {
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding: 12px;
  margin-left: 30px;
  position: absolute;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12));
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
    width: 9px;
    height: 4px;
    background-color: #fff;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
}