@import "src/scss/_common/common";

.wrap {
  font-size: rem(14px);

  &__customStyles {
    font-size: .7rem;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute !important;
    top: -30px;
    left: 0;
    width: 100%;

    @media (max-width: 1600px) {
      top: -10px;
    }
  }
}

.col {
  display: flex;
  align-items: center;
}

.colRight {
  justify-content: flex-end;

  & > a {
    margin-left: 12px;
  }
}

.hiddenWindow{
  display: none;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.customStyles {
  font-size: .7rem;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}