@import "../../scss/_common/common";

.wrapper__block {
  display: flex;
  justify-content: space-between;
}

.block {
  width: 100%;
  max-width: 570px;
  &:not(:last-child) {
    margin-right: 20px;
  }
  &__header {
    padding: 20px 28px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    border: 1px solid #d8dfec;
    background-color: #edeef0;
    border-radius: 16px 16px 0 0;
  }
  &__content {
    padding: 20px 28px;
    background-color: #fff;
    border: 1px solid #d8dfec;
    border-radius: 0 0 16px 16px;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
  }
}

.review {
  &_client {
    &__block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    &__item {
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
    }
    &__jde {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      margin-right: 5px;
    }
    &__star {
      &_count {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-left: 5px;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      width: 100%;

      & > textarea {
        width: 100%;
      }
    }

    &__footer {
      padding-top: 20px;
      border-top: 1px solid #d8dfec;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      line-height: 18px;
    }
    &__name {
      font-weight: 700;
    }
    &__profile {
      font-weight: 400;
      color: #5755d1;
    }
    &__copy {
      & > div {
        width: 25px;
        & > div {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.moderator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  &__label {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #828282;

    strong {
      color: #000;
    }
  }
}

.message {
  &__item {}
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  &__block {
    background: #f9f9fa;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 12px;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 20px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history {
  &__item {
    display: flex;
  }

  &__title {
    white-space: nowrap;
  }

  &__new {
    margin-bottom: 10px;
    width: 250px;
    display: inline-block;
    overflow: auto;
  }

  &__old {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 250px;
    display: inline-block;
    overflow: auto;
  }
}