@import "../../../../scss/_common/common";

.block_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;

  &__left {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;

    @media screen and (max-width: 880px) {
      margin-top: 16px;
    }
  }

  &__input {
    margin-right: 24px;
    width: 310px;
    & > div {
      margin-bottom: 0;
    }
  }

  &__button {
    display: flex;
    align-items: center;

    & > button,
    & > div {
      min-height: 51px;
      margin-right: 12px;
    }
  }
}

.table_wrapper {
  & > div {
    overflow: auto;
    max-height: 550px;
  }
}


button.btn_danger {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}