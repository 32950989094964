@import "./src/scss/_common/common";

.radio {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;

    &:not(:last-child) {
      margin-right: 28px;
    }
  }

  &__input {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 999;
    width: 18px;
    height: 18px;
    cursor: pointer;

    &:checked + .radio__label {
      &:before {}
      &:after {
        opacity: 1;
      }
    }

    &:disabled + .radio__label {
      &:before {
        border-color: #a2b3c2;
        background: #edeef0;
      }
      &:after {
        opacity: 0;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    padding-left: 25px;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
      border: 1px solid #5755d1;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .2s;
    }
    &:before {
      z-index: 1;
    }

    &:after {
      z-index: 2;
      border-width: 5px;
      opacity: 0;
    }
  }
}