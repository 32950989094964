@import "src/scss/_common/common";


.title {
  margin-bottom: rem(50px);

  & > small {
    word-break: break-all;
  }
}

.titleSingle {
  margin-bottom: 0;
}