@import "src/scss/_common/common";

.wrap {}

.list {
  @extend %ex_list-unstyled;
}

.item {
  margin: 0;
}

.link {
  @extend %ex_res-btn;

  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: rem(10px) rem(10px) rem(10px) rem(30px);
  text-align: left;
  color: inherit;
  background: transparent;

  span {
    position: absolute;
    z-index: 4;
    top: 50%;
    font-size: rem(10px);
    width: 1em;
    height: 1em;
    border-radius: 2em;
    margin-top: -0.5em;
    margin-left: rem(4px);
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}