@import "./src/scss/_common/common";

.wrap {
  display: flex;
  align-items: center;

  .imgBox {
    margin-right: rem(20px);
  }

}

.sizeSm {
  .imgBox {
    $sz: 80px;

    width: rem($sz);
    height: rem($sz);
  }
}

.imgBox {
  $sz: $drop-zone__size;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5755d1;
  padding: 10px 16px;
  border-radius: 12px;
  cursor: pointer;
  color: #fff;
  transition: all 0.3s;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    background-color: #6f6ee7;
  }

  > input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
  }

  > img {
    object-fit: cover;
    height: 50px;
    width: 50px;
  }

  & > i {
    color: #fff;
    margin-right: 6px;
  }
}

.imgBoxError {
  background: rgba(red, 0.35);
}


.inputHidden {
  @extend %ex_input-hidden;
}

.remove {
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0;

  & > img {
    max-width: max-content;
  }
}

.img {
  background: transparent;
  padding: 0;
  border: none !important;
  border-radius: 0 !important;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;

    & > a {
      font-size: 15px;
      color: #000;
      text-decoration: underline;
      cursor: pointer;
      z-index: 2;
    }
  }
}

.border {
  min-width: 150px;
  & > div[class*="InputFileDocument_imgBox"] {
    width: 100%;
    margin-right: 0;
    border: 2px dashed #5755D1;
    border-radius: 12px;
    background-color: #FFF;
    color: #5755D1;
    padding: 14px;

    & > i {
      color: #5755D1;
    }

    & > img {
      width: 100%;
    }
  }
}