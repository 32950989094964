@import "../../scss/_common/common";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &__block {
    display: flex;
    align-items: center;

    & > div:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.title {
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  text-transform: uppercase;
}

.top {
  margin-bottom: 20px;
  border: 1px solid #dedede;
  border-radius: 15px;

  &__title {
    margin-bottom: 20px;
    font-size: 20px;
    color: #000;
    background-color: #dedede;
    border-radius: 10px 10px 0 0;
    padding: 10px;
  }
  &__bottom {
    padding: 15px;
  }
  &__block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__item {
    &:not(:first-child) {
      margin-right: 25px;
    }
  }
}

.input {
  &__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    & > div:first-child {
      min-width: 200px;
    }
  }
  &__order {
    width: 100%;
  }
}

.input{
  &__select{
    & div[class*=-control]{
      min-height: 52px;
      font-size: 15px;
      line-height: 20px;
      color: #000;
      width: 100%;
      outline: none;
      background: #fff;
      border: 1px solid #d8dfec;
      border-radius: 12px;
      transition: border 0.2s ease;
    }

    & div[class*=-menu]{
      z-index: 99;
    }
  }
}

.btn__block {
  display: flex;
  align-items: center;
  & > button:not(:last-child) {
    margin-right: 15px;
  }
}

.warehouse__block {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    overflow: auto;
    max-height: 275px;
    width: 100%;
    margin: 0;

    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      color: #000;

      div {
        margin-bottom: 8px;
      }
    }
  }
}

ul.no_scroll {
  max-height: 100%;
}

